import React, {useContext} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {ProviderProfileContext} from "../../ProviderProfileProvider";

export const ProviderInfo = () => {

  const {user} = useAuth0();

  const profile = useContext(ProviderProfileContext);
  
  return <div style={{textAlign: 'start'}}>
    <div style={{marginBottom: 20, fontWeight: 'bold'}}>
      Welcome to your kenko provider page, {user?.nickname}!
    </div>

    <pre style={{color: 'lightgray'}}>
  {JSON.stringify(profile, null, 2)}
    </pre>

  </div>
}