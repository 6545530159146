import React from "react";
import {useAuth0} from '@auth0/auth0-react';

interface Props {
}

export const Header = (props: Props) => {

  const {user, logout} = useAuth0();
  const host = window.location.host

  return (
      <div className={'Header'}>
        <div className={'HeaderLeftElement'}>
          kenko&nbsp;
          {host.startsWith("localhost") && <span style={{color:'blue'}}>local&nbsp;</span>}
          {host.endsWith("thymebook.com") && <span style={{color:'green'}}>dev&nbsp;</span>}
          provider
        </div>
        <div className={'HeaderRightElement'}>
          {user && <>
            <strong>{user?.email}</strong> |
            <button onClick={(() => logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            }))}>logout
            </button>
          </>}
        </div>

      </div>
  );
} 