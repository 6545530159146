import React, {useContext, useState} from 'react';
import {useOutletContext} from "react-router-dom";
import {CreateServiceRequest, CreateServiceResponse, ProviderServiceProto} from "../../provider_api";
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../rpcSender";
import {ProviderProfileContext, useProviderProfile} from "../../ProviderProfileProvider";

export const Services = () => {

  const {profile, setProfile} = useProviderProfile();
  
  const {getAccessTokenSilently} = useAuth0();
  const [newService, setNewService] = useState<ProviderServiceProto | null>();
  const [rpcError, setRpcError] = useState<string>();

  type ProviderLambda = (service: ProviderServiceProto) => void;

  /**
   * Updates the new service in the state. This has to make a copy of the original
   * and then set to comply with react's requirements about not mutating state variables
   * directly.
   */
  const updateService = (lambda: ProviderLambda) => {
    let copy = new ProviderServiceProto(newService ?? ProviderServiceProto.create());
    lambda(copy);
    copy.currencyCode = 'USD'
    setNewService(copy);
  }

  const createNewService = () => {

    // Clear the error
    setRpcError("");

    let request = CreateServiceRequest.encode(new CreateServiceRequest({
      service: newService
    })).finish();

    SendRpc(getAccessTokenSilently, 'create_service', request)
        .then(bytes => {
          let response = CreateServiceResponse.decode(bytes)
          if (response.error) {
            setRpcError(response.error)
          } else if (!response.okay) {
            setRpcError('Error creating service')
          }

          if (response.newProfile) {
            setProfile(response.newProfile);

            // This resets the form.
            setNewService(null);
          }
        }).catch(reason => {
      setRpcError('Error creating service, code: ' + 32)
    })
  }

  return (<div style={{display: 'flex', flexDirection: 'column', gap: 10, textAlign: 'start'}}>
    <b>my services:</b>

    <table style={{}}>
      <tr>
        <td>sku</td>
        <td>name</td>
        <td>category</td>
        <td>description</td>
        <td>price (usd)</td>
        <td>length (min)</td>
      </tr>

      {profile?.services?.map(service => {
        return <tr>
          <td>{service.sku}</td>
          <td>{service.name}</td>
          <td>{service.category}</td>
          <td>{service.description}</td>
          <td>{service.price}</td>
          <td>{service.lengthMinutes}</td>
        </tr>
      })}

    </table>

    {!profile?.services && <div>
      You don't have any services defined! Click below to create a new one.
    </div>}

    <br/>
    <br/>


    {!newService &&
        <button onClick={() => setNewService(new ProviderServiceProto())}>
          create a new service!
        </button>
    }

    {newService &&
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <form style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            <label>
              SKU: <input type='text' onChange={e => {
              updateService((service) => service.sku = e.target.value);
            }}
            />
              <div style={{color: 'gray', fontStyle: 'italic'}}>a permanent, unique value for record keeping</div>
            </label>
            <label>
              Name: <input type='text' onChange={e => {
              updateService((service) => service.name = e.target.value);
            }}/>
            </label>
            <label>
              Description: <input type='text' onChange={e => {
              updateService((service) => service.description = e.target.value);
            }}/>
            </label>
            <label>
              Category: <input type='text' onChange={e => {
              updateService((service) => service.category = e.target.value);
            }}/>
            </label>
            <label>
              Length (minutes): <input type='number' onChange={e => {
              updateService((service) => service.lengthMinutes = parseInt(e.target.value));
            }}/>
            </label>
            <label>
              Price (USD): $<input type='number' onChange={e => {
              updateService((service) => service.price = parseFloat(e.target.value));
            }}/>
            </label>
            <button onClick={event => {
              event.preventDefault();
              createNewService();
            }}>
              create
            </button>
          </form>

          {rpcError && <div style={{color: 'red'}}>{rpcError}</div>}

          <pre style={{color: 'lightgray'}}>
            {JSON.stringify(newService, null, 2)}
          </pre>
        </div>
    }
  </div>);
}