import React, {useState} from 'react';
import {UpdateProviderProfileRequest, UpdateProviderProfileResponse} from "../../../provider_api";
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../rpcSender";
import './NewProviderSurvey.css';
import {useNavigate} from "react-router-dom";
import SurveyMapInput from "./SurveyMapInput";
import {useProviderProfile} from "../../../ProviderProfileProvider";

const Survey = () => {

  const {profile, setProfile} = useProviderProfile();
  
  const {getAccessTokenSilently} = useAuth0();

  const [currentStep, setCurrentStep] = useState(0);

  // Survey results are kept as a plain JSON object and then converted to proto
  // at the end. This turned out way easier than trying to convert types and mash
  // things into a proto right away.
  const [surveyResults, setSurveyResults] = useState<{ [key: string]: any }>({});

  // Client-side error validating one of the fields
  const [validationError, setValidationError] = useState<string | null>(null);

  // If there was an RPC error sending the profile.
  const [updateProfileError, setUpdateProfileError] = useState('');

  const navigate = useNavigate();

  const sendUpdateProviderProfileRequest = () => {

    return new Promise<UpdateProviderProfileResponse>((resolve, reject) => {

          // Build up the thing from the survey questions
          const request = UpdateProviderProfileRequest.create({
            timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
          });
          
          surveyPages.forEach(page => {
            if (page.visibleWhen(surveyResults) && surveyResults[page.name]) {
              page.setter(request, surveyResults[page.name])
            }
          });

          console.log('creating profile with', JSON.stringify(request, null, 2));

          let encoded: Uint8Array = UpdateProviderProfileRequest.encode(request).finish();

          SendRpc(getAccessTokenSilently, 'update_provider_profile', encoded)
              .then(value => {
                resolve(UpdateProviderProfileResponse.decode(value));
              })
              .catch(e => {
                reject(e);
              });
        }
    )
  };

  const shortUrlRegex = /^[a-z]+(-[a-z]+)*$/

  type surveyPage = {
    name: string,
    title: () => string,
    subtitle?: () => string,
    type: string,
    choices?: { value: string, text: string }[],
    visibleWhen: (results: any) => boolean,
    setter: (request: UpdateProviderProfileRequest, value: any) => void,

    // Optional -- in case you need to handle something when the value changes.
    // Like setting a suggested short url from the provider name..
    onValueChange?: (value: any) => void,
    isValidValue: (value: any) => boolean,
    validationMessage: string,
    initialValue?: any,
  }

  const surveyPages: surveyPage[] = [
    {
      name: 'ProviderName',
      title: () => 'What\'s the name of your practice?',
      type: 'text',
      visibleWhen: () => true,
      setter: (request: UpdateProviderProfileRequest, value: string) => {
        request.providerName = value.trim();
      },
      isValidValue: (value) => value?.trim().length > 4,
      onValueChange: (value: string) => {

        // They can change this later but here's an easy version.
        let suggestedShortUrl = value.trim();

        suggestedShortUrl = suggestedShortUrl.toLowerCase();

        // All whitespace with a single dash
        suggestedShortUrl = suggestedShortUrl.replace(/[\s]+/g, "-");

        // Remove all non-numbers and non-lowercase letters
        suggestedShortUrl = suggestedShortUrl.replace(/[^a-z0-9-]+/g, "");

        console.log('suggesting short url', suggestedShortUrl)

        // It's possible that the survey results get mutated multiple
        // times before the next render! Because the onValueChange
        // also can do a mutate. Therefore use a function to change it.
        setSurveyResults(surveyResults => {
          return {
            ...surveyResults,
            'ShortUrl': suggestedShortUrl
          }
        });
      },
      validationMessage: '',
    },
    {
      name: 'Description',
      title: () => 'Tell us about your practice!',
      subtitle: () => 'Please make it descriptive! This will show up on your landing page. 2-3 paragraphs is ideal.',
      type: 'textarea',
      visibleWhen: (results) => true,
      setter: (request: UpdateProviderProfileRequest, value: string) => {
        request.providerDescription = value;
      },
      isValidValue: (value) => true,
      validationMessage: 'Please enter a valid description. ',
    },

    {
      name: 'CompanySize',
      title: () => 'What size is your company?',
      type: 'radio',
      visibleWhen: (results) => (results['CurrentlyPregnant'] as boolean),
      choices: [
        {value: "1", text: 'Just me.'},
        {value: "2", text: '2-5 people'},
        {value: "6", text: '6+ people'},
      ],
      setter: (request: UpdateProviderProfileRequest, value: string) => {
      },
      isValidValue: (value) => {
        return value != null;
      },
      validationMessage: 'Please select one',
    },
    {
      name: 'Location',
      title: () => 'Set your location',
      type: 'location',
      visibleWhen: (results) => true,
      setter: (request: UpdateProviderProfileRequest, location: google.maps.places.PlaceResult | null) => {
        request.address = location?.formatted_address || "";
        request.lat = location?.geometry?.location?.lat() || 0
        request.lng = location?.geometry?.location?.lng() || 0
      },
      isValidValue: (value) => {
        return value != null;
      },
      validationMessage: 'Please choose a location',
    },
    {
      name: 'ShortUrl',
      title: () => 'Please choose a short url for your booking page',
      subtitle: () => 'This must contain only lowercase letters and dashes only, and be at least 8 characters long. ' +
          '(e.g `fur-by-gordie` or `joes-hair-salon`)',
      type: 'text',
      visibleWhen: (results) => true,
      setter: (request: UpdateProviderProfileRequest, value: string) => {
        request.providerShortUrl = value;
      },
      isValidValue: (value) => {
        console.log('testing', value)
        return value && (value as string).match(shortUrlRegex);
      },
      validationMessage: 'Please enter a valid short URL. ',
    },
    {
      name: 'HowDidYouHear',
      title: () => 'How did you hear about us?',
      type: 'radio',
      visibleWhen: (results) => !results['CurrentlyPregnant'],
      choices: [
        {value: 'FromAFriend', text: 'From a friend'},
        {value: 'Social', text: 'Social Media'},
        {value: 'Other', text: 'Other'},
      ],
      setter: (request: UpdateProviderProfileRequest, value: string) => {
      },
      isValidValue: (value) => {
        return value;
      },
      validationMessage: 'Please select one',
    },
  ];

  /**
   * @param results - the current survey results. this typically uses the react state
   *                  "surveyResults", but you can also pass in a modified once, which is
   *                  necessary when you mutate state (e.g. select a radio button) AND
   *                  want to figure out the next state immediately.
   *
   * @return {int} the next visible step, or the array length if there are no further pages.
   * By returning the array length we know that the
   */
  const getNextVisibleStep = (results: any) => {
    for (let i = currentStep + 1; i < surveyPages.length; i++) {
      if (surveyPages[i].visibleWhen(results)) {
        return i;
      }
    }

    return surveyPages.length;
  };

  /**
   * @return {int} The previous step or -1 if there is no previous step
   */
  const getPreviousVisibleStep = (results: any) => {
    for (let i = currentStep - 1; i >= 0; i--) {
      if (surveyPages[i].visibleWhen(results)) {
        return i;
      }
    }

    return -1;
  };

  const page = surveyPages[currentStep];

  /**
   *
   * @param results the most recent survey results--- make sure not to grab the state
   *                variable if you have mutated it in this frame! pass a new copy.
   *                this is important when using radio buttons
   */
  const advancePage = (results: any) => {
    const nextStep = getNextVisibleStep(results);

    if (nextStep < surveyPages.length) {
      // There's still another page to go. Advance the step

      setCurrentStep(nextStep);
      setValidationError(null);

      const nextPage = surveyPages[nextStep];

      // Set the default value! This only needs to be done in the forward direction.
      // When the page gets rendered the first time the default value (if there is one)
      // will be put in the survey results. 
      if (surveyResults[nextPage.name] == null && nextPage.initialValue != null) {
        console.log('[Survey] Populating initial value for step', nextPage.name,
            nextPage.initialValue);

        setSurveyResults({
          ...results,
          [nextPage.name]: nextPage.initialValue,
        });
      }
    } else {

      // This is the last step! Kick off the RPC. Do not advance the page.
      sendUpdateProviderProfileRequest().then(profile => {

        if (profile.profile) {
          console.info('[Survey] Profile send success!');
          
          setProfile(profile.profile)

          // Would be better to use navigate but oh well.
          navigate('/')

        } else {
          console.warn('[Survey] Profile send failure!', profile.error);
          setUpdateProfileError(profile.error);
          return;
        }

      }).catch(e => {
        console.warn('[Survey] Profile send failure!', e);
        setUpdateProfileError(e);
      });
    }

  };

  const goBackAPage = (results: any) => {
    // This just clears out the RPC error when going back
    setUpdateProfileError('');
    setCurrentStep(getPreviousVisibleStep(results));
    setValidationError(null);
  };

  // This is a null parameter if there is no next step available which hides the button
  const onBackPress = (getPreviousVisibleStep(surveyResults) >= 0) ? (() => {
        goBackAPage(surveyResults);
      })
      : null;

  return (
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 20,
        padding: 20,
      }}>

        { /*progress bar*/}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          columnGap: 10,
        }}>
          {new Array(5).fill(0).map((_, i) => {
            return (
                <div key={i} style={{
                  height: 5,
                  width: 50,
                  borderRadius: 3,
                  backgroundColor: currentStep / 2 > i ? 'green' : 'gray',
                }}/>
            );
          })}
        </div>

        <span style={{
          margin: 20,
          textAlign: 'center',
          fontWeight: 'bold',
        }}>
        {page.title()}</span>

        {page.subtitle &&
            <span style={{
              marginBottom: 10,
              marginLeft: 44,
              marginRight: 44,
              textAlign: 'center',
            }}>{page.subtitle()}</span>}

        {
            page.type == 'text' && (
                <input type={'text'} style={{
                  borderWidth: 1,
                  width: 250,
                  padding: 10,
                }}
                       placeholder={'Enter text here'}
                       value={surveyResults[page.name] || ''}
                       onChange={event => {

                         // If the onValueChange callback exists then call that.
                         page.onValueChange && page.onValueChange(event.target.value);

                         // It's possible that the survey results get mutated multiple
                         // times before the next render! Because the onValueChange
                         // also can do a mutate. Therefore use a function to change it.
                         setSurveyResults(surveyResults => {
                               return {
                                 ...surveyResults,
                                 [page.name]: event.target.value,
                               }
                             }
                         );
                       }}
                />
            )
        }

        {
            page.type == 'textarea' && (
                <textarea rows={10} cols={50} style={{
                  borderWidth: 1,
                  padding: 10,
                }}
                       placeholder={'Enter text here'}
                       value={surveyResults[page.name] || ''}
                       onChange={event => {

                         // If the onValueChange callback exists then call that.
                         page.onValueChange && page.onValueChange(event.target.value);

                         // It's possible that the survey results get mutated multiple
                         // times before the next render! Because the onValueChange
                         // also can do a mutate. Therefore use a function to change it.
                         setSurveyResults(surveyResults => {
                               return {
                                 ...surveyResults,
                                 [page.name]: event.target.value,
                               }
                             }
                         );
                       }}
                />
            )
        }


        {
            page.type == 'radio' && page.choices?.map((choice, i) => {

              // This is essentially the same as a checklist except only one is selected
              return (
                  <div
                      key={i}
                      className={'survey-checklist ' +
                          ((surveyResults[page.name] == choice.value) ? 'selected' : 'notSelected')}
                      onClick={() => {
                        const newSurveyResults = {
                          ...surveyResults,
                          [page.name]: choice.value,
                        };

                        setSurveyResults(newSurveyResults);
                      }}>
                    {choice.text}
                  </div>);
            })
        }

        {
            page.type == 'checklist' && page.choices?.map((choice, i) => {
              return (
                  <button
                      key={i}
                      className={'survey-checklist ' +
                          ((surveyResults[page.name]?.includes(choice.value)) ? 'selected' : 'notSelected')}
                      onClick={() => {

                        if (!surveyResults[page.name]) {
                          // The list was empty. Create new.
                          setSurveyResults({
                            ...surveyResults,
                            [page.name]: [choice.value],
                          });

                        } else if (surveyResults[page.name].includes(choice.value)) {
                          // Deselecting (remove each instance from list)
                          setSurveyResults({
                            ...surveyResults,
                            [page.name]: surveyResults[page.name].filter(
                                (item: any) => {
                                  return item != choice.value;
                                }),
                          });

                        } else {
                          // Selecting (append to list)
                          setSurveyResults({
                            ...surveyResults,
                            [page.name]: [
                              ...surveyResults[page.name],
                              (choice.value)],
                          });
                        }
                      }}>
                    {
                      choice.text
                    }
                  </button>);
            })
        }

        {page.type == 'location' && <SurveyMapInput onLocationPicked={(location) => {
          setSurveyResults({
            ...surveyResults,
            [page.name]: location
          });
        }}/>}

        {validationError && (<div
            style={{
              fontSize: 16,
              color: 'red',
              margin: 25,
            }}>{validationError}</div>)}

        <button style={{
          width: 250,
          height: 40,
          backgroundColor: 'green',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '0px solid inset',
          borderRadius: 5,
          overflow: 'hidden',
        }} onClick={() => {

          if (!page.isValidValue(surveyResults[page.name])) {
            setValidationError(page.validationMessage);
          } else {

            // It's okay to use the state variable to advance a page here because
            // there is a re-render between the time the users clicks a value and
            // hits the continue button.
            advancePage(surveyResults);
          }
        }}>
          Continue
        </button>

        {updateProfileError &&
            (<span style={{color: 'red', padding: 5}}>
        Error building your profile: {updateProfileError}
        </span>)
        }

        {onBackPress &&
            <button style={{
              width: 150,
              height: 20,
              marginTop: 30,
              padding: 15,
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'gray',
              border: '1px solid lightgray',
              borderRadius: 5,
              overflow: 'hidden',
            }} onClick={() => {
              onBackPress();
            }}>
              Back
            </button>
        }

        <div style={{fontSize: 'smaller', color: '#ccc'}}>
          {JSON.stringify(surveyResults)}
        </div>

      </div>
  );
};

export default Survey;