import React, {FormEvent, useState} from 'react'
import {useProviderProfile} from "../../../ProviderProfileProvider";
import {SendRpc} from "../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import {
  AvailabilityBlockProto, IAvailabilityBlockProto,
  space,
  UpdateAvailabilityRequest,
  UpdateAvailabilityResponse
} from "../../../provider_api";
import LocalTimeProto = space.kenko.proto.LocalTimeProto;


export const MySchedule = () => {

  // iso-8601 standards, to map a number (1-7) to the day.
  // This also gets used by the day of week selector hence the 0
  // being the "Select..." 
  const daysOfWeek = [
    "Select...",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const {profile, setProfile} = useProviderProfile();
  const {getAccessTokenSilently} = useAuth0();

  const [dayOfWeek, setDayOfWeek] = useState(0);
  const [start, setStart] = useState<string>("09:00");
  const [end, setEnd] = useState<string>("17:00");

  const [rpcError, setRpcError] = useState<string>();

  const padMinute = (minute: number | null | undefined) => {
    return String(minute).padStart(2, '0')
  }

  const deleteAvailability = (block: IAvailabilityBlockProto) => {

    let request = UpdateAvailabilityRequest.encode(new UpdateAvailabilityRequest({
      dayOfWeek: block.dayOfWeek,
      startTime: block.startTime,
      endTime: block.endTime
    })).finish();

    setRpcError('');
    SendRpc(getAccessTokenSilently, "delete_availability", request)
        .then(r => {
          let response = UpdateAvailabilityResponse.decode(r)
          if (response.okay) {
            setProfile(response.newProfile);
          } else {
            setRpcError('Error deleting availability: ' + response.error)
          }
        })
        .catch(e => {
          setRpcError('Error deleting availability: ' + e)
        })
  }


  const addAvailability = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let request = UpdateAvailabilityRequest.encode(new UpdateAvailabilityRequest({
      dayOfWeek: dayOfWeek,
      startTime: new LocalTimeProto({
        hour: parseInt(start.split(":")[0]),
        minute: parseInt(start.split(":")[1]),
      }),
      endTime: new LocalTimeProto({
        hour: parseInt(end.split(":")[0]),
        minute: parseInt(end.split(":")[1]),
      })
    })).finish();

    SendRpc(getAccessTokenSilently, "update_availability", request)
        .then(r => {
          let response = UpdateAvailabilityResponse.decode(r)
          if (response.okay) {
            setProfile(response.newProfile);
          } else {
            setRpcError('Error adding availability: ' + response.error)
          }
        })
        .catch(e => {
          setRpcError('Error adding availability: ' + e)
        })
  }

  return <div>

    <b>My schedule:</b> (time zone: {profile?.defaultTimeZone})
    <br/><br/>
    {profile?.defaultAvailabilityBlocks?.length == 0 && <div>
      You haven't configured any available time yet. Please add some below.
    </div>}

    {profile?.defaultAvailabilityBlocks?.map(block => {
      return <div>
        {daysOfWeek[block.dayOfWeek as number]}:
        {block.startTime?.hour}:{padMinute(block.startTime?.minute)} to&nbsp;
        {block.endTime?.hour}:{padMinute(block.endTime?.minute)}
        <button style={{margin: 2}} onClick={(e) => {
          // e.preventDefault() // don't add to history.
          if (window.confirm("Delete this availability block? " +
              "Existing appointments within this time window will not be changed. " +
              "You will have to cancel them manually if the time no longer works.")) {
            deleteAvailability(block);
          }
        }}>remove</button>
      </div>
    })}

    <br/><br/>

    <b>Add some availability</b>
    <form onSubmit={addAvailability} style={{marginTop: 10}}>

      <select name="day" style={{height: 25}}
              onChange={e => setDayOfWeek(parseInt(e.target.value))}>
        {daysOfWeek.map((name, index) => <option value={index}>{name}</option>)}
      </select>

      &nbsp;&nbsp;&nbsp;

      <input name="start" type={'time'}
             value={start}
             style={{width: 100}}
             onChange={e => setStart(e.target.value)}/>

      &nbsp; to &nbsp;

      <input name="end" type={'time'}
             value={end}
             step={900}
             style={{width: 100}}
             onChange={e => setEnd(e.target.value)}/>

      <div style={{marginTop: 10}}>
        <button type={"submit"}>update</button>
      </div>

    </form>

    {rpcError && <div style={{color: 'red'}}>{rpcError}</div>}

  </div>
}
    