import React, {useContext, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../rpcSender";
import {DebugDeleteProviderRequest, DebugDeleteProviderResponse} from "../../provider_api";
import {useNavigate} from "react-router-dom";
import {ProviderProfileContext} from "../../ProviderProfileProvider";

export const DeleteBusinessDebug = () => {

  const {profile, setProfile} = useContext(ProviderProfileContext);
  const {getAccessTokenSilently} = useAuth0();
  const [error, setError] = useState<string>()
  const navigate = useNavigate();

  const deleteRpc = () => {

    let request = DebugDeleteProviderRequest.encode(new DebugDeleteProviderRequest({
      providerId: profile?.providerId
    })).finish();
    
    SendRpc(getAccessTokenSilently, 'debug_delete_provider', request)
        .then(value => {
          let response = DebugDeleteProviderResponse.decode(value);
          if (response.okay) {
            setProfile(null);
          } else {
            setError("error deleting the thing")
          }

        })
        .catch(e => {
          console.log(e);
          setError("error deleting the thing")
        });
  }


  return (
      <>
        <button onClick={deleteRpc}>Delete it for real?!</button>
        {error && <div style={{color: 'red'}}>error deleting profile</div>}
      </>
  );
}