/* global google */

import {Routes, Route} from "react-router";
import React, {useContext} from 'react';
import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import {ProviderProfileContext} from "../ProviderProfileProvider";
import {Header} from "./Header";
import {ProviderHome} from "./Home/ProviderHome";
import {RedeemInvite} from "./RedeemInvite/RedeemInvite";

/**
 * This is the parent of everything on the site. It acts as a login gate
 * so we only allow people in the whitelist. If the user is not logged
 * in they are prompted to log in.
 *
 * If they're not in the whitelist they are just not permitted.
 *
 * If they're in, then the rest of the app renders.
 */
export const App = () => {

  const {isLoading, user, loginWithRedirect} = useAuth0();

  const {profile, profileError, profileLoading} = useContext(ProviderProfileContext);

  return (
      <div className="App">
        <Header/>
        <div className='Body'>

          <div style={{
            flex: 1,
            minHeight: 300,
            minWidth: 500,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            marginTop: 20,
          }}>

            <Body/>

          </div>
        </div>

        <div className={'Footer}'}>
          copyright 2024 kenko
        </div>
      </div>
  );
}

/**
 * This is extracted from above so I can do if/else clauses for all the possible
 * options rather than {!profileError && !profileLoading && profile && ..} nonsense.
 */
const Body = () => {

  const {isLoading, user, loginWithRedirect} = useAuth0();
  const {profile, profileError, profileLoading} = useContext(ProviderProfileContext);

  const auth0Login = () => {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      },
      authorizationParams: {
        audience: `https://narwhal.kenko.space/`,
        scope: 'openid offline_access' // to get refresh token
      }
    });
  }

  if (isLoading) {
    return <div>Loading user...</div>
  }

  if (!user) {
    return <div>
      Please sign in to access the Kenko provider portal.
      <div style={{margin: 10}}>
        <button onClick={auth0Login}>Sign in</button>
      </div>
    </div>
  }


  if (profileLoading) {
    return <div>Loading profile...</div>;
  }

  if (profileError) {
    return <div style={{color: 'red'}}>
      Error loading your profile: {profileError}
    </div>;
  }

  if (!profile) {
    return <Routes>
      <Route path="/redeemInvite/:inviteCode" element={<RedeemInvite/>}/>
      <Route path="*" element={<div>
        We're sorry but we don't see a provider profile for this login.
        <br/><br/>
        <a href={'mailto:info@kenko.space'}>Contact us</a> if you're interested in becoming a kenko provider
      </div>}/>
    </Routes>
  }

  // All is good! There are sub-routes in ProviderHome.
  return <ProviderHome/>
}

export default App;
