/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.ProviderServiceProto = (function() {

    /**
     * Properties of a ProviderServiceProto.
     * @exports IProviderServiceProto
     * @interface IProviderServiceProto
     * @property {string|null} [sku] ProviderServiceProto sku
     * @property {string|null} [name] ProviderServiceProto name
     * @property {string|null} [description] ProviderServiceProto description
     * @property {number|null} [lengthMinutes] ProviderServiceProto lengthMinutes
     * @property {number|null} [price] ProviderServiceProto price
     * @property {string|null} [currencyCode] ProviderServiceProto currencyCode
     * @property {string|null} [category] ProviderServiceProto category
     */

    /**
     * Constructs a new ProviderServiceProto.
     * @exports ProviderServiceProto
     * @classdesc Represents a ProviderServiceProto.
     * @implements IProviderServiceProto
     * @constructor
     * @param {IProviderServiceProto=} [properties] Properties to set
     */
    function ProviderServiceProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProviderServiceProto sku.
     * @member {string} sku
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.sku = "";

    /**
     * ProviderServiceProto name.
     * @member {string} name
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.name = "";

    /**
     * ProviderServiceProto description.
     * @member {string} description
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.description = "";

    /**
     * ProviderServiceProto lengthMinutes.
     * @member {number} lengthMinutes
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.lengthMinutes = 0;

    /**
     * ProviderServiceProto price.
     * @member {number} price
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.price = 0;

    /**
     * ProviderServiceProto currencyCode.
     * @member {string} currencyCode
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.currencyCode = "";

    /**
     * ProviderServiceProto category.
     * @member {string} category
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.category = "";

    /**
     * Creates a new ProviderServiceProto instance using the specified properties.
     * @function create
     * @memberof ProviderServiceProto
     * @static
     * @param {IProviderServiceProto=} [properties] Properties to set
     * @returns {ProviderServiceProto} ProviderServiceProto instance
     */
    ProviderServiceProto.create = function create(properties) {
        return new ProviderServiceProto(properties);
    };

    /**
     * Encodes the specified ProviderServiceProto message. Does not implicitly {@link ProviderServiceProto.verify|verify} messages.
     * @function encode
     * @memberof ProviderServiceProto
     * @static
     * @param {IProviderServiceProto} message ProviderServiceProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderServiceProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
        if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
        if (message.price != null && Object.hasOwnProperty.call(message, "price"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.price);
        if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.currencyCode);
        if (message.category != null && Object.hasOwnProperty.call(message, "category"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.category);
        return writer;
    };

    /**
     * Encodes the specified ProviderServiceProto message, length delimited. Does not implicitly {@link ProviderServiceProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProviderServiceProto
     * @static
     * @param {IProviderServiceProto} message ProviderServiceProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProviderServiceProto message from the specified reader or buffer.
     * @function decode
     * @memberof ProviderServiceProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProviderServiceProto} ProviderServiceProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderServiceProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProviderServiceProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sku = reader.string();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.description = reader.string();
                    break;
                }
            case 4: {
                    message.lengthMinutes = reader.int32();
                    break;
                }
            case 5: {
                    message.price = reader.int32();
                    break;
                }
            case 6: {
                    message.currencyCode = reader.string();
                    break;
                }
            case 7: {
                    message.category = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProviderServiceProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProviderServiceProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProviderServiceProto} ProviderServiceProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderServiceProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProviderServiceProto message.
     * @function verify
     * @memberof ProviderServiceProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProviderServiceProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.sku != null && message.hasOwnProperty("sku"))
            if (!$util.isString(message.sku))
                return "sku: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.description != null && message.hasOwnProperty("description"))
            if (!$util.isString(message.description))
                return "description: string expected";
        if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
            if (!$util.isInteger(message.lengthMinutes))
                return "lengthMinutes: integer expected";
        if (message.price != null && message.hasOwnProperty("price"))
            if (!$util.isInteger(message.price))
                return "price: integer expected";
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            if (!$util.isString(message.currencyCode))
                return "currencyCode: string expected";
        if (message.category != null && message.hasOwnProperty("category"))
            if (!$util.isString(message.category))
                return "category: string expected";
        return null;
    };

    /**
     * Creates a ProviderServiceProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProviderServiceProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProviderServiceProto} ProviderServiceProto
     */
    ProviderServiceProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ProviderServiceProto)
            return object;
        var message = new $root.ProviderServiceProto();
        if (object.sku != null)
            message.sku = String(object.sku);
        if (object.name != null)
            message.name = String(object.name);
        if (object.description != null)
            message.description = String(object.description);
        if (object.lengthMinutes != null)
            message.lengthMinutes = object.lengthMinutes | 0;
        if (object.price != null)
            message.price = object.price | 0;
        if (object.currencyCode != null)
            message.currencyCode = String(object.currencyCode);
        if (object.category != null)
            message.category = String(object.category);
        return message;
    };

    /**
     * Creates a plain object from a ProviderServiceProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProviderServiceProto
     * @static
     * @param {ProviderServiceProto} message ProviderServiceProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProviderServiceProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sku = "";
            object.name = "";
            object.description = "";
            object.lengthMinutes = 0;
            object.price = 0;
            object.currencyCode = "";
            object.category = "";
        }
        if (message.sku != null && message.hasOwnProperty("sku"))
            object.sku = message.sku;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.description != null && message.hasOwnProperty("description"))
            object.description = message.description;
        if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
            object.lengthMinutes = message.lengthMinutes;
        if (message.price != null && message.hasOwnProperty("price"))
            object.price = message.price;
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            object.currencyCode = message.currencyCode;
        if (message.category != null && message.hasOwnProperty("category"))
            object.category = message.category;
        return object;
    };

    /**
     * Converts this ProviderServiceProto to JSON.
     * @function toJSON
     * @memberof ProviderServiceProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProviderServiceProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProviderServiceProto
     * @function getTypeUrl
     * @memberof ProviderServiceProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProviderServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProviderServiceProto";
    };

    return ProviderServiceProto;
})();

$root.ProviderProfileProto = (function() {

    /**
     * Properties of a ProviderProfileProto.
     * @exports IProviderProfileProto
     * @interface IProviderProfileProto
     * @property {string|null} [providerId] ProviderProfileProto providerId
     * @property {string|null} [providerName] ProviderProfileProto providerName
     * @property {string|null} [providerShortUrl] ProviderProfileProto providerShortUrl
     * @property {string|null} [providerDescription] ProviderProfileProto providerDescription
     * @property {Array.<IProviderServiceProto>|null} [services] ProviderProfileProto services
     * @property {string|null} [address] ProviderProfileProto address
     * @property {number|null} [lat] ProviderProfileProto lat
     * @property {number|null} [lng] ProviderProfileProto lng
     * @property {string|null} [defaultTimeZone] ProviderProfileProto defaultTimeZone
     * @property {Array.<IAvailabilityBlockProto>|null} [defaultAvailabilityBlocks] ProviderProfileProto defaultAvailabilityBlocks
     */

    /**
     * Constructs a new ProviderProfileProto.
     * @exports ProviderProfileProto
     * @classdesc Represents a ProviderProfileProto.
     * @implements IProviderProfileProto
     * @constructor
     * @param {IProviderProfileProto=} [properties] Properties to set
     */
    function ProviderProfileProto(properties) {
        this.services = [];
        this.defaultAvailabilityBlocks = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProviderProfileProto providerId.
     * @member {string} providerId
     * @memberof ProviderProfileProto
     * @instance
     */
    ProviderProfileProto.prototype.providerId = "";

    /**
     * ProviderProfileProto providerName.
     * @member {string} providerName
     * @memberof ProviderProfileProto
     * @instance
     */
    ProviderProfileProto.prototype.providerName = "";

    /**
     * ProviderProfileProto providerShortUrl.
     * @member {string} providerShortUrl
     * @memberof ProviderProfileProto
     * @instance
     */
    ProviderProfileProto.prototype.providerShortUrl = "";

    /**
     * ProviderProfileProto providerDescription.
     * @member {string} providerDescription
     * @memberof ProviderProfileProto
     * @instance
     */
    ProviderProfileProto.prototype.providerDescription = "";

    /**
     * ProviderProfileProto services.
     * @member {Array.<IProviderServiceProto>} services
     * @memberof ProviderProfileProto
     * @instance
     */
    ProviderProfileProto.prototype.services = $util.emptyArray;

    /**
     * ProviderProfileProto address.
     * @member {string} address
     * @memberof ProviderProfileProto
     * @instance
     */
    ProviderProfileProto.prototype.address = "";

    /**
     * ProviderProfileProto lat.
     * @member {number} lat
     * @memberof ProviderProfileProto
     * @instance
     */
    ProviderProfileProto.prototype.lat = 0;

    /**
     * ProviderProfileProto lng.
     * @member {number} lng
     * @memberof ProviderProfileProto
     * @instance
     */
    ProviderProfileProto.prototype.lng = 0;

    /**
     * ProviderProfileProto defaultTimeZone.
     * @member {string} defaultTimeZone
     * @memberof ProviderProfileProto
     * @instance
     */
    ProviderProfileProto.prototype.defaultTimeZone = "";

    /**
     * ProviderProfileProto defaultAvailabilityBlocks.
     * @member {Array.<IAvailabilityBlockProto>} defaultAvailabilityBlocks
     * @memberof ProviderProfileProto
     * @instance
     */
    ProviderProfileProto.prototype.defaultAvailabilityBlocks = $util.emptyArray;

    /**
     * Creates a new ProviderProfileProto instance using the specified properties.
     * @function create
     * @memberof ProviderProfileProto
     * @static
     * @param {IProviderProfileProto=} [properties] Properties to set
     * @returns {ProviderProfileProto} ProviderProfileProto instance
     */
    ProviderProfileProto.create = function create(properties) {
        return new ProviderProfileProto(properties);
    };

    /**
     * Encodes the specified ProviderProfileProto message. Does not implicitly {@link ProviderProfileProto.verify|verify} messages.
     * @function encode
     * @memberof ProviderProfileProto
     * @static
     * @param {IProviderProfileProto} message ProviderProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderProfileProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerName);
        if (message.providerShortUrl != null && Object.hasOwnProperty.call(message, "providerShortUrl"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.providerShortUrl);
        if (message.providerDescription != null && Object.hasOwnProperty.call(message, "providerDescription"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.providerDescription);
        if (message.services != null && message.services.length)
            for (var i = 0; i < message.services.length; ++i)
                $root.ProviderServiceProto.encode(message.services[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.address != null && Object.hasOwnProperty.call(message, "address"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.address);
        if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
            writer.uint32(/* id 15, wireType 1 =*/121).double(message.lat);
        if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
            writer.uint32(/* id 16, wireType 1 =*/129).double(message.lng);
        if (message.defaultTimeZone != null && Object.hasOwnProperty.call(message, "defaultTimeZone"))
            writer.uint32(/* id 20, wireType 2 =*/162).string(message.defaultTimeZone);
        if (message.defaultAvailabilityBlocks != null && message.defaultAvailabilityBlocks.length)
            for (var i = 0; i < message.defaultAvailabilityBlocks.length; ++i)
                $root.AvailabilityBlockProto.encode(message.defaultAvailabilityBlocks[i], writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ProviderProfileProto message, length delimited. Does not implicitly {@link ProviderProfileProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProviderProfileProto
     * @static
     * @param {IProviderProfileProto} message ProviderProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProviderProfileProto message from the specified reader or buffer.
     * @function decode
     * @memberof ProviderProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProviderProfileProto} ProviderProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderProfileProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProviderProfileProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.providerName = reader.string();
                    break;
                }
            case 3: {
                    message.providerShortUrl = reader.string();
                    break;
                }
            case 4: {
                    message.providerDescription = reader.string();
                    break;
                }
            case 10: {
                    if (!(message.services && message.services.length))
                        message.services = [];
                    message.services.push($root.ProviderServiceProto.decode(reader, reader.uint32()));
                    break;
                }
            case 14: {
                    message.address = reader.string();
                    break;
                }
            case 15: {
                    message.lat = reader.double();
                    break;
                }
            case 16: {
                    message.lng = reader.double();
                    break;
                }
            case 20: {
                    message.defaultTimeZone = reader.string();
                    break;
                }
            case 21: {
                    if (!(message.defaultAvailabilityBlocks && message.defaultAvailabilityBlocks.length))
                        message.defaultAvailabilityBlocks = [];
                    message.defaultAvailabilityBlocks.push($root.AvailabilityBlockProto.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProviderProfileProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProviderProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProviderProfileProto} ProviderProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderProfileProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProviderProfileProto message.
     * @function verify
     * @memberof ProviderProfileProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProviderProfileProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.providerName != null && message.hasOwnProperty("providerName"))
            if (!$util.isString(message.providerName))
                return "providerName: string expected";
        if (message.providerShortUrl != null && message.hasOwnProperty("providerShortUrl"))
            if (!$util.isString(message.providerShortUrl))
                return "providerShortUrl: string expected";
        if (message.providerDescription != null && message.hasOwnProperty("providerDescription"))
            if (!$util.isString(message.providerDescription))
                return "providerDescription: string expected";
        if (message.services != null && message.hasOwnProperty("services")) {
            if (!Array.isArray(message.services))
                return "services: array expected";
            for (var i = 0; i < message.services.length; ++i) {
                var error = $root.ProviderServiceProto.verify(message.services[i]);
                if (error)
                    return "services." + error;
            }
        }
        if (message.address != null && message.hasOwnProperty("address"))
            if (!$util.isString(message.address))
                return "address: string expected";
        if (message.lat != null && message.hasOwnProperty("lat"))
            if (typeof message.lat !== "number")
                return "lat: number expected";
        if (message.lng != null && message.hasOwnProperty("lng"))
            if (typeof message.lng !== "number")
                return "lng: number expected";
        if (message.defaultTimeZone != null && message.hasOwnProperty("defaultTimeZone"))
            if (!$util.isString(message.defaultTimeZone))
                return "defaultTimeZone: string expected";
        if (message.defaultAvailabilityBlocks != null && message.hasOwnProperty("defaultAvailabilityBlocks")) {
            if (!Array.isArray(message.defaultAvailabilityBlocks))
                return "defaultAvailabilityBlocks: array expected";
            for (var i = 0; i < message.defaultAvailabilityBlocks.length; ++i) {
                var error = $root.AvailabilityBlockProto.verify(message.defaultAvailabilityBlocks[i]);
                if (error)
                    return "defaultAvailabilityBlocks." + error;
            }
        }
        return null;
    };

    /**
     * Creates a ProviderProfileProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProviderProfileProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProviderProfileProto} ProviderProfileProto
     */
    ProviderProfileProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ProviderProfileProto)
            return object;
        var message = new $root.ProviderProfileProto();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.providerName != null)
            message.providerName = String(object.providerName);
        if (object.providerShortUrl != null)
            message.providerShortUrl = String(object.providerShortUrl);
        if (object.providerDescription != null)
            message.providerDescription = String(object.providerDescription);
        if (object.services) {
            if (!Array.isArray(object.services))
                throw TypeError(".ProviderProfileProto.services: array expected");
            message.services = [];
            for (var i = 0; i < object.services.length; ++i) {
                if (typeof object.services[i] !== "object")
                    throw TypeError(".ProviderProfileProto.services: object expected");
                message.services[i] = $root.ProviderServiceProto.fromObject(object.services[i]);
            }
        }
        if (object.address != null)
            message.address = String(object.address);
        if (object.lat != null)
            message.lat = Number(object.lat);
        if (object.lng != null)
            message.lng = Number(object.lng);
        if (object.defaultTimeZone != null)
            message.defaultTimeZone = String(object.defaultTimeZone);
        if (object.defaultAvailabilityBlocks) {
            if (!Array.isArray(object.defaultAvailabilityBlocks))
                throw TypeError(".ProviderProfileProto.defaultAvailabilityBlocks: array expected");
            message.defaultAvailabilityBlocks = [];
            for (var i = 0; i < object.defaultAvailabilityBlocks.length; ++i) {
                if (typeof object.defaultAvailabilityBlocks[i] !== "object")
                    throw TypeError(".ProviderProfileProto.defaultAvailabilityBlocks: object expected");
                message.defaultAvailabilityBlocks[i] = $root.AvailabilityBlockProto.fromObject(object.defaultAvailabilityBlocks[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a ProviderProfileProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProviderProfileProto
     * @static
     * @param {ProviderProfileProto} message ProviderProfileProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProviderProfileProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults) {
            object.services = [];
            object.defaultAvailabilityBlocks = [];
        }
        if (options.defaults) {
            object.providerId = "";
            object.providerName = "";
            object.providerShortUrl = "";
            object.providerDescription = "";
            object.address = "";
            object.lat = 0;
            object.lng = 0;
            object.defaultTimeZone = "";
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.providerName != null && message.hasOwnProperty("providerName"))
            object.providerName = message.providerName;
        if (message.providerShortUrl != null && message.hasOwnProperty("providerShortUrl"))
            object.providerShortUrl = message.providerShortUrl;
        if (message.providerDescription != null && message.hasOwnProperty("providerDescription"))
            object.providerDescription = message.providerDescription;
        if (message.services && message.services.length) {
            object.services = [];
            for (var j = 0; j < message.services.length; ++j)
                object.services[j] = $root.ProviderServiceProto.toObject(message.services[j], options);
        }
        if (message.address != null && message.hasOwnProperty("address"))
            object.address = message.address;
        if (message.lat != null && message.hasOwnProperty("lat"))
            object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
        if (message.lng != null && message.hasOwnProperty("lng"))
            object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
        if (message.defaultTimeZone != null && message.hasOwnProperty("defaultTimeZone"))
            object.defaultTimeZone = message.defaultTimeZone;
        if (message.defaultAvailabilityBlocks && message.defaultAvailabilityBlocks.length) {
            object.defaultAvailabilityBlocks = [];
            for (var j = 0; j < message.defaultAvailabilityBlocks.length; ++j)
                object.defaultAvailabilityBlocks[j] = $root.AvailabilityBlockProto.toObject(message.defaultAvailabilityBlocks[j], options);
        }
        return object;
    };

    /**
     * Converts this ProviderProfileProto to JSON.
     * @function toJSON
     * @memberof ProviderProfileProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProviderProfileProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProviderProfileProto
     * @function getTypeUrl
     * @memberof ProviderProfileProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProviderProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProviderProfileProto";
    };

    return ProviderProfileProto;
})();

$root.AvailabilityBlockProto = (function() {

    /**
     * Properties of an AvailabilityBlockProto.
     * @exports IAvailabilityBlockProto
     * @interface IAvailabilityBlockProto
     * @property {number|null} [dayOfWeek] AvailabilityBlockProto dayOfWeek
     * @property {space.kenko.proto.ILocalTimeProto|null} [startTime] AvailabilityBlockProto startTime
     * @property {space.kenko.proto.ILocalTimeProto|null} [endTime] AvailabilityBlockProto endTime
     */

    /**
     * Constructs a new AvailabilityBlockProto.
     * @exports AvailabilityBlockProto
     * @classdesc Represents an AvailabilityBlockProto.
     * @implements IAvailabilityBlockProto
     * @constructor
     * @param {IAvailabilityBlockProto=} [properties] Properties to set
     */
    function AvailabilityBlockProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AvailabilityBlockProto dayOfWeek.
     * @member {number} dayOfWeek
     * @memberof AvailabilityBlockProto
     * @instance
     */
    AvailabilityBlockProto.prototype.dayOfWeek = 0;

    /**
     * AvailabilityBlockProto startTime.
     * @member {space.kenko.proto.ILocalTimeProto|null|undefined} startTime
     * @memberof AvailabilityBlockProto
     * @instance
     */
    AvailabilityBlockProto.prototype.startTime = null;

    /**
     * AvailabilityBlockProto endTime.
     * @member {space.kenko.proto.ILocalTimeProto|null|undefined} endTime
     * @memberof AvailabilityBlockProto
     * @instance
     */
    AvailabilityBlockProto.prototype.endTime = null;

    /**
     * Creates a new AvailabilityBlockProto instance using the specified properties.
     * @function create
     * @memberof AvailabilityBlockProto
     * @static
     * @param {IAvailabilityBlockProto=} [properties] Properties to set
     * @returns {AvailabilityBlockProto} AvailabilityBlockProto instance
     */
    AvailabilityBlockProto.create = function create(properties) {
        return new AvailabilityBlockProto(properties);
    };

    /**
     * Encodes the specified AvailabilityBlockProto message. Does not implicitly {@link AvailabilityBlockProto.verify|verify} messages.
     * @function encode
     * @memberof AvailabilityBlockProto
     * @static
     * @param {IAvailabilityBlockProto} message AvailabilityBlockProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AvailabilityBlockProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.dayOfWeek != null && Object.hasOwnProperty.call(message, "dayOfWeek"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dayOfWeek);
        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
            $root.space.kenko.proto.LocalTimeProto.encode(message.startTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
            $root.space.kenko.proto.LocalTimeProto.encode(message.endTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AvailabilityBlockProto message, length delimited. Does not implicitly {@link AvailabilityBlockProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AvailabilityBlockProto
     * @static
     * @param {IAvailabilityBlockProto} message AvailabilityBlockProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AvailabilityBlockProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AvailabilityBlockProto message from the specified reader or buffer.
     * @function decode
     * @memberof AvailabilityBlockProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AvailabilityBlockProto} AvailabilityBlockProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AvailabilityBlockProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AvailabilityBlockProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.dayOfWeek = reader.int32();
                    break;
                }
            case 2: {
                    message.startTime = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.endTime = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AvailabilityBlockProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AvailabilityBlockProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AvailabilityBlockProto} AvailabilityBlockProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AvailabilityBlockProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AvailabilityBlockProto message.
     * @function verify
     * @memberof AvailabilityBlockProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AvailabilityBlockProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
            if (!$util.isInteger(message.dayOfWeek))
                return "dayOfWeek: integer expected";
        if (message.startTime != null && message.hasOwnProperty("startTime")) {
            var error = $root.space.kenko.proto.LocalTimeProto.verify(message.startTime);
            if (error)
                return "startTime." + error;
        }
        if (message.endTime != null && message.hasOwnProperty("endTime")) {
            var error = $root.space.kenko.proto.LocalTimeProto.verify(message.endTime);
            if (error)
                return "endTime." + error;
        }
        return null;
    };

    /**
     * Creates an AvailabilityBlockProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AvailabilityBlockProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AvailabilityBlockProto} AvailabilityBlockProto
     */
    AvailabilityBlockProto.fromObject = function fromObject(object) {
        if (object instanceof $root.AvailabilityBlockProto)
            return object;
        var message = new $root.AvailabilityBlockProto();
        if (object.dayOfWeek != null)
            message.dayOfWeek = object.dayOfWeek | 0;
        if (object.startTime != null) {
            if (typeof object.startTime !== "object")
                throw TypeError(".AvailabilityBlockProto.startTime: object expected");
            message.startTime = $root.space.kenko.proto.LocalTimeProto.fromObject(object.startTime);
        }
        if (object.endTime != null) {
            if (typeof object.endTime !== "object")
                throw TypeError(".AvailabilityBlockProto.endTime: object expected");
            message.endTime = $root.space.kenko.proto.LocalTimeProto.fromObject(object.endTime);
        }
        return message;
    };

    /**
     * Creates a plain object from an AvailabilityBlockProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AvailabilityBlockProto
     * @static
     * @param {AvailabilityBlockProto} message AvailabilityBlockProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AvailabilityBlockProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.dayOfWeek = 0;
            object.startTime = null;
            object.endTime = null;
        }
        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
            object.dayOfWeek = message.dayOfWeek;
        if (message.startTime != null && message.hasOwnProperty("startTime"))
            object.startTime = $root.space.kenko.proto.LocalTimeProto.toObject(message.startTime, options);
        if (message.endTime != null && message.hasOwnProperty("endTime"))
            object.endTime = $root.space.kenko.proto.LocalTimeProto.toObject(message.endTime, options);
        return object;
    };

    /**
     * Converts this AvailabilityBlockProto to JSON.
     * @function toJSON
     * @memberof AvailabilityBlockProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AvailabilityBlockProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AvailabilityBlockProto
     * @function getTypeUrl
     * @memberof AvailabilityBlockProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AvailabilityBlockProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AvailabilityBlockProto";
    };

    return AvailabilityBlockProto;
})();

$root.GetProviderProfileRequest = (function() {

    /**
     * Properties of a GetProviderProfileRequest.
     * @exports IGetProviderProfileRequest
     * @interface IGetProviderProfileRequest
     */

    /**
     * Constructs a new GetProviderProfileRequest.
     * @exports GetProviderProfileRequest
     * @classdesc Represents a GetProviderProfileRequest.
     * @implements IGetProviderProfileRequest
     * @constructor
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     */
    function GetProviderProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetProviderProfileRequest instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest instance
     */
    GetProviderProfileRequest.create = function create(properties) {
        return new GetProviderProfileRequest(properties);
    };

    /**
     * Encodes the specified GetProviderProfileRequest message. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileRequest message, length delimited. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileRequest message.
     * @function verify
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetProviderProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     */
    GetProviderProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileRequest)
            return object;
        return new $root.GetProviderProfileRequest();
    };

    /**
     * Creates a plain object from a GetProviderProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {GetProviderProfileRequest} message GetProviderProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetProviderProfileRequest to JSON.
     * @function toJSON
     * @memberof GetProviderProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileRequest
     * @function getTypeUrl
     * @memberof GetProviderProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileRequest";
    };

    return GetProviderProfileRequest;
})();

$root.GetProviderProfileResponse = (function() {

    /**
     * Properties of a GetProviderProfileResponse.
     * @exports IGetProviderProfileResponse
     * @interface IGetProviderProfileResponse
     * @property {boolean|null} [okay] GetProviderProfileResponse okay
     * @property {IProviderProfileProto|null} [profile] GetProviderProfileResponse profile
     */

    /**
     * Constructs a new GetProviderProfileResponse.
     * @exports GetProviderProfileResponse
     * @classdesc Represents a GetProviderProfileResponse.
     * @implements IGetProviderProfileResponse
     * @constructor
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     */
    function GetProviderProfileResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetProviderProfileResponse okay.
     * @member {boolean} okay
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.okay = false;

    /**
     * GetProviderProfileResponse profile.
     * @member {IProviderProfileProto|null|undefined} profile
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.profile = null;

    /**
     * Creates a new GetProviderProfileResponse instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse instance
     */
    GetProviderProfileResponse.create = function create(properties) {
        return new GetProviderProfileResponse(properties);
    };

    /**
     * Encodes the specified GetProviderProfileResponse message. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.ProviderProfileProto.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileResponse message, length delimited. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.profile = $root.ProviderProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileResponse message.
     * @function verify
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.ProviderProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates a GetProviderProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     */
    GetProviderProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileResponse)
            return object;
        var message = new $root.GetProviderProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".GetProviderProfileResponse.profile: object expected");
            message.profile = $root.ProviderProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetProviderProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {GetProviderProfileResponse} message GetProviderProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.profile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.ProviderProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this GetProviderProfileResponse to JSON.
     * @function toJSON
     * @memberof GetProviderProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileResponse
     * @function getTypeUrl
     * @memberof GetProviderProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileResponse";
    };

    return GetProviderProfileResponse;
})();

$root.UpdateProviderProfileRequest = (function() {

    /**
     * Properties of an UpdateProviderProfileRequest.
     * @exports IUpdateProviderProfileRequest
     * @interface IUpdateProviderProfileRequest
     * @property {string|null} [providerShortUrl] UpdateProviderProfileRequest providerShortUrl
     * @property {number|null} [lat] UpdateProviderProfileRequest lat
     * @property {number|null} [lng] UpdateProviderProfileRequest lng
     * @property {string|null} [timeZoneId] UpdateProviderProfileRequest timeZoneId
     * @property {string|null} [providerName] UpdateProviderProfileRequest providerName
     * @property {string|null} [providerDescription] UpdateProviderProfileRequest providerDescription
     * @property {string|null} [contactEmail] UpdateProviderProfileRequest contactEmail
     * @property {string|null} [address] UpdateProviderProfileRequest address
     */

    /**
     * Constructs a new UpdateProviderProfileRequest.
     * @exports UpdateProviderProfileRequest
     * @classdesc Represents an UpdateProviderProfileRequest.
     * @implements IUpdateProviderProfileRequest
     * @constructor
     * @param {IUpdateProviderProfileRequest=} [properties] Properties to set
     */
    function UpdateProviderProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateProviderProfileRequest providerShortUrl.
     * @member {string} providerShortUrl
     * @memberof UpdateProviderProfileRequest
     * @instance
     */
    UpdateProviderProfileRequest.prototype.providerShortUrl = "";

    /**
     * UpdateProviderProfileRequest lat.
     * @member {number} lat
     * @memberof UpdateProviderProfileRequest
     * @instance
     */
    UpdateProviderProfileRequest.prototype.lat = 0;

    /**
     * UpdateProviderProfileRequest lng.
     * @member {number} lng
     * @memberof UpdateProviderProfileRequest
     * @instance
     */
    UpdateProviderProfileRequest.prototype.lng = 0;

    /**
     * UpdateProviderProfileRequest timeZoneId.
     * @member {string} timeZoneId
     * @memberof UpdateProviderProfileRequest
     * @instance
     */
    UpdateProviderProfileRequest.prototype.timeZoneId = "";

    /**
     * UpdateProviderProfileRequest providerName.
     * @member {string} providerName
     * @memberof UpdateProviderProfileRequest
     * @instance
     */
    UpdateProviderProfileRequest.prototype.providerName = "";

    /**
     * UpdateProviderProfileRequest providerDescription.
     * @member {string} providerDescription
     * @memberof UpdateProviderProfileRequest
     * @instance
     */
    UpdateProviderProfileRequest.prototype.providerDescription = "";

    /**
     * UpdateProviderProfileRequest contactEmail.
     * @member {string} contactEmail
     * @memberof UpdateProviderProfileRequest
     * @instance
     */
    UpdateProviderProfileRequest.prototype.contactEmail = "";

    /**
     * UpdateProviderProfileRequest address.
     * @member {string} address
     * @memberof UpdateProviderProfileRequest
     * @instance
     */
    UpdateProviderProfileRequest.prototype.address = "";

    /**
     * Creates a new UpdateProviderProfileRequest instance using the specified properties.
     * @function create
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {IUpdateProviderProfileRequest=} [properties] Properties to set
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest instance
     */
    UpdateProviderProfileRequest.create = function create(properties) {
        return new UpdateProviderProfileRequest(properties);
    };

    /**
     * Encodes the specified UpdateProviderProfileRequest message. Does not implicitly {@link UpdateProviderProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {IUpdateProviderProfileRequest} message UpdateProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerShortUrl != null && Object.hasOwnProperty.call(message, "providerShortUrl"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerShortUrl);
        if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
            writer.uint32(/* id 2, wireType 1 =*/17).double(message.lat);
        if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
            writer.uint32(/* id 3, wireType 1 =*/25).double(message.lng);
        if (message.timeZoneId != null && Object.hasOwnProperty.call(message, "timeZoneId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.timeZoneId);
        if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.providerName);
        if (message.providerDescription != null && Object.hasOwnProperty.call(message, "providerDescription"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.providerDescription);
        if (message.contactEmail != null && Object.hasOwnProperty.call(message, "contactEmail"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.contactEmail);
        if (message.address != null && Object.hasOwnProperty.call(message, "address"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.address);
        return writer;
    };

    /**
     * Encodes the specified UpdateProviderProfileRequest message, length delimited. Does not implicitly {@link UpdateProviderProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {IUpdateProviderProfileRequest} message UpdateProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateProviderProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateProviderProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerShortUrl = reader.string();
                    break;
                }
            case 2: {
                    message.lat = reader.double();
                    break;
                }
            case 3: {
                    message.lng = reader.double();
                    break;
                }
            case 4: {
                    message.timeZoneId = reader.string();
                    break;
                }
            case 10: {
                    message.providerName = reader.string();
                    break;
                }
            case 11: {
                    message.providerDescription = reader.string();
                    break;
                }
            case 12: {
                    message.contactEmail = reader.string();
                    break;
                }
            case 13: {
                    message.address = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateProviderProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateProviderProfileRequest message.
     * @function verify
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateProviderProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerShortUrl != null && message.hasOwnProperty("providerShortUrl"))
            if (!$util.isString(message.providerShortUrl))
                return "providerShortUrl: string expected";
        if (message.lat != null && message.hasOwnProperty("lat"))
            if (typeof message.lat !== "number")
                return "lat: number expected";
        if (message.lng != null && message.hasOwnProperty("lng"))
            if (typeof message.lng !== "number")
                return "lng: number expected";
        if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
            if (!$util.isString(message.timeZoneId))
                return "timeZoneId: string expected";
        if (message.providerName != null && message.hasOwnProperty("providerName"))
            if (!$util.isString(message.providerName))
                return "providerName: string expected";
        if (message.providerDescription != null && message.hasOwnProperty("providerDescription"))
            if (!$util.isString(message.providerDescription))
                return "providerDescription: string expected";
        if (message.contactEmail != null && message.hasOwnProperty("contactEmail"))
            if (!$util.isString(message.contactEmail))
                return "contactEmail: string expected";
        if (message.address != null && message.hasOwnProperty("address"))
            if (!$util.isString(message.address))
                return "address: string expected";
        return null;
    };

    /**
     * Creates an UpdateProviderProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateProviderProfileRequest} UpdateProviderProfileRequest
     */
    UpdateProviderProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateProviderProfileRequest)
            return object;
        var message = new $root.UpdateProviderProfileRequest();
        if (object.providerShortUrl != null)
            message.providerShortUrl = String(object.providerShortUrl);
        if (object.lat != null)
            message.lat = Number(object.lat);
        if (object.lng != null)
            message.lng = Number(object.lng);
        if (object.timeZoneId != null)
            message.timeZoneId = String(object.timeZoneId);
        if (object.providerName != null)
            message.providerName = String(object.providerName);
        if (object.providerDescription != null)
            message.providerDescription = String(object.providerDescription);
        if (object.contactEmail != null)
            message.contactEmail = String(object.contactEmail);
        if (object.address != null)
            message.address = String(object.address);
        return message;
    };

    /**
     * Creates a plain object from an UpdateProviderProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {UpdateProviderProfileRequest} message UpdateProviderProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateProviderProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.providerShortUrl = "";
            object.lat = 0;
            object.lng = 0;
            object.timeZoneId = "";
            object.providerName = "";
            object.providerDescription = "";
            object.contactEmail = "";
            object.address = "";
        }
        if (message.providerShortUrl != null && message.hasOwnProperty("providerShortUrl"))
            object.providerShortUrl = message.providerShortUrl;
        if (message.lat != null && message.hasOwnProperty("lat"))
            object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
        if (message.lng != null && message.hasOwnProperty("lng"))
            object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
        if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
            object.timeZoneId = message.timeZoneId;
        if (message.providerName != null && message.hasOwnProperty("providerName"))
            object.providerName = message.providerName;
        if (message.providerDescription != null && message.hasOwnProperty("providerDescription"))
            object.providerDescription = message.providerDescription;
        if (message.contactEmail != null && message.hasOwnProperty("contactEmail"))
            object.contactEmail = message.contactEmail;
        if (message.address != null && message.hasOwnProperty("address"))
            object.address = message.address;
        return object;
    };

    /**
     * Converts this UpdateProviderProfileRequest to JSON.
     * @function toJSON
     * @memberof UpdateProviderProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateProviderProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateProviderProfileRequest
     * @function getTypeUrl
     * @memberof UpdateProviderProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateProviderProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateProviderProfileRequest";
    };

    return UpdateProviderProfileRequest;
})();

$root.UpdateProviderProfileResponse = (function() {

    /**
     * Properties of an UpdateProviderProfileResponse.
     * @exports IUpdateProviderProfileResponse
     * @interface IUpdateProviderProfileResponse
     * @property {boolean|null} [okay] UpdateProviderProfileResponse okay
     * @property {IProviderProfileProto|null} [profile] UpdateProviderProfileResponse profile
     * @property {string|null} [error] UpdateProviderProfileResponse error
     */

    /**
     * Constructs a new UpdateProviderProfileResponse.
     * @exports UpdateProviderProfileResponse
     * @classdesc Represents an UpdateProviderProfileResponse.
     * @implements IUpdateProviderProfileResponse
     * @constructor
     * @param {IUpdateProviderProfileResponse=} [properties] Properties to set
     */
    function UpdateProviderProfileResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateProviderProfileResponse okay.
     * @member {boolean} okay
     * @memberof UpdateProviderProfileResponse
     * @instance
     */
    UpdateProviderProfileResponse.prototype.okay = false;

    /**
     * UpdateProviderProfileResponse profile.
     * @member {IProviderProfileProto|null|undefined} profile
     * @memberof UpdateProviderProfileResponse
     * @instance
     */
    UpdateProviderProfileResponse.prototype.profile = null;

    /**
     * UpdateProviderProfileResponse error.
     * @member {string} error
     * @memberof UpdateProviderProfileResponse
     * @instance
     */
    UpdateProviderProfileResponse.prototype.error = "";

    /**
     * Creates a new UpdateProviderProfileResponse instance using the specified properties.
     * @function create
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {IUpdateProviderProfileResponse=} [properties] Properties to set
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse instance
     */
    UpdateProviderProfileResponse.create = function create(properties) {
        return new UpdateProviderProfileResponse(properties);
    };

    /**
     * Encodes the specified UpdateProviderProfileResponse message. Does not implicitly {@link UpdateProviderProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {IUpdateProviderProfileResponse} message UpdateProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.ProviderProfileProto.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.error);
        return writer;
    };

    /**
     * Encodes the specified UpdateProviderProfileResponse message, length delimited. Does not implicitly {@link UpdateProviderProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {IUpdateProviderProfileResponse} message UpdateProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateProviderProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateProviderProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateProviderProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.profile = $root.ProviderProfileProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.error = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateProviderProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateProviderProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateProviderProfileResponse message.
     * @function verify
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateProviderProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.ProviderProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        return null;
    };

    /**
     * Creates an UpdateProviderProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateProviderProfileResponse} UpdateProviderProfileResponse
     */
    UpdateProviderProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateProviderProfileResponse)
            return object;
        var message = new $root.UpdateProviderProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".UpdateProviderProfileResponse.profile: object expected");
            message.profile = $root.ProviderProfileProto.fromObject(object.profile);
        }
        if (object.error != null)
            message.error = String(object.error);
        return message;
    };

    /**
     * Creates a plain object from an UpdateProviderProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {UpdateProviderProfileResponse} message UpdateProviderProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateProviderProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.profile = null;
            object.error = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.ProviderProfileProto.toObject(message.profile, options);
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        return object;
    };

    /**
     * Converts this UpdateProviderProfileResponse to JSON.
     * @function toJSON
     * @memberof UpdateProviderProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateProviderProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateProviderProfileResponse
     * @function getTypeUrl
     * @memberof UpdateProviderProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateProviderProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateProviderProfileResponse";
    };

    return UpdateProviderProfileResponse;
})();

$root.DebugDeleteProviderRequest = (function() {

    /**
     * Properties of a DebugDeleteProviderRequest.
     * @exports IDebugDeleteProviderRequest
     * @interface IDebugDeleteProviderRequest
     */

    /**
     * Constructs a new DebugDeleteProviderRequest.
     * @exports DebugDeleteProviderRequest
     * @classdesc Represents a DebugDeleteProviderRequest.
     * @implements IDebugDeleteProviderRequest
     * @constructor
     * @param {IDebugDeleteProviderRequest=} [properties] Properties to set
     */
    function DebugDeleteProviderRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new DebugDeleteProviderRequest instance using the specified properties.
     * @function create
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {IDebugDeleteProviderRequest=} [properties] Properties to set
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest instance
     */
    DebugDeleteProviderRequest.create = function create(properties) {
        return new DebugDeleteProviderRequest(properties);
    };

    /**
     * Encodes the specified DebugDeleteProviderRequest message. Does not implicitly {@link DebugDeleteProviderRequest.verify|verify} messages.
     * @function encode
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {IDebugDeleteProviderRequest} message DebugDeleteProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified DebugDeleteProviderRequest message, length delimited. Does not implicitly {@link DebugDeleteProviderRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {IDebugDeleteProviderRequest} message DebugDeleteProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DebugDeleteProviderRequest message from the specified reader or buffer.
     * @function decode
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DebugDeleteProviderRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DebugDeleteProviderRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DebugDeleteProviderRequest message.
     * @function verify
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DebugDeleteProviderRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a DebugDeleteProviderRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DebugDeleteProviderRequest} DebugDeleteProviderRequest
     */
    DebugDeleteProviderRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.DebugDeleteProviderRequest)
            return object;
        return new $root.DebugDeleteProviderRequest();
    };

    /**
     * Creates a plain object from a DebugDeleteProviderRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {DebugDeleteProviderRequest} message DebugDeleteProviderRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DebugDeleteProviderRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this DebugDeleteProviderRequest to JSON.
     * @function toJSON
     * @memberof DebugDeleteProviderRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DebugDeleteProviderRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DebugDeleteProviderRequest
     * @function getTypeUrl
     * @memberof DebugDeleteProviderRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DebugDeleteProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DebugDeleteProviderRequest";
    };

    return DebugDeleteProviderRequest;
})();

$root.DebugDeleteProviderResponse = (function() {

    /**
     * Properties of a DebugDeleteProviderResponse.
     * @exports IDebugDeleteProviderResponse
     * @interface IDebugDeleteProviderResponse
     * @property {boolean|null} [okay] DebugDeleteProviderResponse okay
     */

    /**
     * Constructs a new DebugDeleteProviderResponse.
     * @exports DebugDeleteProviderResponse
     * @classdesc Represents a DebugDeleteProviderResponse.
     * @implements IDebugDeleteProviderResponse
     * @constructor
     * @param {IDebugDeleteProviderResponse=} [properties] Properties to set
     */
    function DebugDeleteProviderResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DebugDeleteProviderResponse okay.
     * @member {boolean} okay
     * @memberof DebugDeleteProviderResponse
     * @instance
     */
    DebugDeleteProviderResponse.prototype.okay = false;

    /**
     * Creates a new DebugDeleteProviderResponse instance using the specified properties.
     * @function create
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {IDebugDeleteProviderResponse=} [properties] Properties to set
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse instance
     */
    DebugDeleteProviderResponse.create = function create(properties) {
        return new DebugDeleteProviderResponse(properties);
    };

    /**
     * Encodes the specified DebugDeleteProviderResponse message. Does not implicitly {@link DebugDeleteProviderResponse.verify|verify} messages.
     * @function encode
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {IDebugDeleteProviderResponse} message DebugDeleteProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        return writer;
    };

    /**
     * Encodes the specified DebugDeleteProviderResponse message, length delimited. Does not implicitly {@link DebugDeleteProviderResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {IDebugDeleteProviderResponse} message DebugDeleteProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DebugDeleteProviderResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DebugDeleteProviderResponse message from the specified reader or buffer.
     * @function decode
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DebugDeleteProviderResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DebugDeleteProviderResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DebugDeleteProviderResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DebugDeleteProviderResponse message.
     * @function verify
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DebugDeleteProviderResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        return null;
    };

    /**
     * Creates a DebugDeleteProviderResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DebugDeleteProviderResponse} DebugDeleteProviderResponse
     */
    DebugDeleteProviderResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.DebugDeleteProviderResponse)
            return object;
        var message = new $root.DebugDeleteProviderResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        return message;
    };

    /**
     * Creates a plain object from a DebugDeleteProviderResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {DebugDeleteProviderResponse} message DebugDeleteProviderResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DebugDeleteProviderResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        return object;
    };

    /**
     * Converts this DebugDeleteProviderResponse to JSON.
     * @function toJSON
     * @memberof DebugDeleteProviderResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DebugDeleteProviderResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DebugDeleteProviderResponse
     * @function getTypeUrl
     * @memberof DebugDeleteProviderResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DebugDeleteProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DebugDeleteProviderResponse";
    };

    return DebugDeleteProviderResponse;
})();

$root.CreateServiceRequest = (function() {

    /**
     * Properties of a CreateServiceRequest.
     * @exports ICreateServiceRequest
     * @interface ICreateServiceRequest
     * @property {IProviderServiceProto|null} [service] CreateServiceRequest service
     */

    /**
     * Constructs a new CreateServiceRequest.
     * @exports CreateServiceRequest
     * @classdesc Represents a CreateServiceRequest.
     * @implements ICreateServiceRequest
     * @constructor
     * @param {ICreateServiceRequest=} [properties] Properties to set
     */
    function CreateServiceRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateServiceRequest service.
     * @member {IProviderServiceProto|null|undefined} service
     * @memberof CreateServiceRequest
     * @instance
     */
    CreateServiceRequest.prototype.service = null;

    /**
     * Creates a new CreateServiceRequest instance using the specified properties.
     * @function create
     * @memberof CreateServiceRequest
     * @static
     * @param {ICreateServiceRequest=} [properties] Properties to set
     * @returns {CreateServiceRequest} CreateServiceRequest instance
     */
    CreateServiceRequest.create = function create(properties) {
        return new CreateServiceRequest(properties);
    };

    /**
     * Encodes the specified CreateServiceRequest message. Does not implicitly {@link CreateServiceRequest.verify|verify} messages.
     * @function encode
     * @memberof CreateServiceRequest
     * @static
     * @param {ICreateServiceRequest} message CreateServiceRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.service != null && Object.hasOwnProperty.call(message, "service"))
            $root.ProviderServiceProto.encode(message.service, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified CreateServiceRequest message, length delimited. Does not implicitly {@link CreateServiceRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateServiceRequest
     * @static
     * @param {ICreateServiceRequest} message CreateServiceRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateServiceRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CreateServiceRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateServiceRequest} CreateServiceRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateServiceRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.service = $root.ProviderServiceProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CreateServiceRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateServiceRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateServiceRequest} CreateServiceRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateServiceRequest message.
     * @function verify
     * @memberof CreateServiceRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateServiceRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.service != null && message.hasOwnProperty("service")) {
            var error = $root.ProviderServiceProto.verify(message.service);
            if (error)
                return "service." + error;
        }
        return null;
    };

    /**
     * Creates a CreateServiceRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateServiceRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateServiceRequest} CreateServiceRequest
     */
    CreateServiceRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateServiceRequest)
            return object;
        var message = new $root.CreateServiceRequest();
        if (object.service != null) {
            if (typeof object.service !== "object")
                throw TypeError(".CreateServiceRequest.service: object expected");
            message.service = $root.ProviderServiceProto.fromObject(object.service);
        }
        return message;
    };

    /**
     * Creates a plain object from a CreateServiceRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateServiceRequest
     * @static
     * @param {CreateServiceRequest} message CreateServiceRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateServiceRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.service = null;
        if (message.service != null && message.hasOwnProperty("service"))
            object.service = $root.ProviderServiceProto.toObject(message.service, options);
        return object;
    };

    /**
     * Converts this CreateServiceRequest to JSON.
     * @function toJSON
     * @memberof CreateServiceRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateServiceRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateServiceRequest
     * @function getTypeUrl
     * @memberof CreateServiceRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateServiceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateServiceRequest";
    };

    return CreateServiceRequest;
})();

$root.CreateServiceResponse = (function() {

    /**
     * Properties of a CreateServiceResponse.
     * @exports ICreateServiceResponse
     * @interface ICreateServiceResponse
     * @property {boolean|null} [okay] CreateServiceResponse okay
     * @property {string|null} [error] CreateServiceResponse error
     * @property {IProviderProfileProto|null} [newProfile] CreateServiceResponse newProfile
     */

    /**
     * Constructs a new CreateServiceResponse.
     * @exports CreateServiceResponse
     * @classdesc Represents a CreateServiceResponse.
     * @implements ICreateServiceResponse
     * @constructor
     * @param {ICreateServiceResponse=} [properties] Properties to set
     */
    function CreateServiceResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateServiceResponse okay.
     * @member {boolean} okay
     * @memberof CreateServiceResponse
     * @instance
     */
    CreateServiceResponse.prototype.okay = false;

    /**
     * CreateServiceResponse error.
     * @member {string} error
     * @memberof CreateServiceResponse
     * @instance
     */
    CreateServiceResponse.prototype.error = "";

    /**
     * CreateServiceResponse newProfile.
     * @member {IProviderProfileProto|null|undefined} newProfile
     * @memberof CreateServiceResponse
     * @instance
     */
    CreateServiceResponse.prototype.newProfile = null;

    /**
     * Creates a new CreateServiceResponse instance using the specified properties.
     * @function create
     * @memberof CreateServiceResponse
     * @static
     * @param {ICreateServiceResponse=} [properties] Properties to set
     * @returns {CreateServiceResponse} CreateServiceResponse instance
     */
    CreateServiceResponse.create = function create(properties) {
        return new CreateServiceResponse(properties);
    };

    /**
     * Encodes the specified CreateServiceResponse message. Does not implicitly {@link CreateServiceResponse.verify|verify} messages.
     * @function encode
     * @memberof CreateServiceResponse
     * @static
     * @param {ICreateServiceResponse} message CreateServiceResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
        if (message.newProfile != null && Object.hasOwnProperty.call(message, "newProfile"))
            $root.ProviderProfileProto.encode(message.newProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified CreateServiceResponse message, length delimited. Does not implicitly {@link CreateServiceResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateServiceResponse
     * @static
     * @param {ICreateServiceResponse} message CreateServiceResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateServiceResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateServiceResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CreateServiceResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateServiceResponse} CreateServiceResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateServiceResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.error = reader.string();
                    break;
                }
            case 3: {
                    message.newProfile = $root.ProviderProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CreateServiceResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateServiceResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateServiceResponse} CreateServiceResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateServiceResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateServiceResponse message.
     * @function verify
     * @memberof CreateServiceResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateServiceResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        if (message.newProfile != null && message.hasOwnProperty("newProfile")) {
            var error = $root.ProviderProfileProto.verify(message.newProfile);
            if (error)
                return "newProfile." + error;
        }
        return null;
    };

    /**
     * Creates a CreateServiceResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateServiceResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateServiceResponse} CreateServiceResponse
     */
    CreateServiceResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateServiceResponse)
            return object;
        var message = new $root.CreateServiceResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.error != null)
            message.error = String(object.error);
        if (object.newProfile != null) {
            if (typeof object.newProfile !== "object")
                throw TypeError(".CreateServiceResponse.newProfile: object expected");
            message.newProfile = $root.ProviderProfileProto.fromObject(object.newProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from a CreateServiceResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateServiceResponse
     * @static
     * @param {CreateServiceResponse} message CreateServiceResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateServiceResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.error = "";
            object.newProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        if (message.newProfile != null && message.hasOwnProperty("newProfile"))
            object.newProfile = $root.ProviderProfileProto.toObject(message.newProfile, options);
        return object;
    };

    /**
     * Converts this CreateServiceResponse to JSON.
     * @function toJSON
     * @memberof CreateServiceResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateServiceResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateServiceResponse
     * @function getTypeUrl
     * @memberof CreateServiceResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateServiceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CreateServiceResponse";
    };

    return CreateServiceResponse;
})();

$root.AcceptProviderInviteRequest = (function() {

    /**
     * Properties of an AcceptProviderInviteRequest.
     * @exports IAcceptProviderInviteRequest
     * @interface IAcceptProviderInviteRequest
     * @property {string|null} [inviteCode] AcceptProviderInviteRequest inviteCode
     * @property {boolean|null} [accepted] AcceptProviderInviteRequest accepted
     */

    /**
     * Constructs a new AcceptProviderInviteRequest.
     * @exports AcceptProviderInviteRequest
     * @classdesc Represents an AcceptProviderInviteRequest.
     * @implements IAcceptProviderInviteRequest
     * @constructor
     * @param {IAcceptProviderInviteRequest=} [properties] Properties to set
     */
    function AcceptProviderInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptProviderInviteRequest inviteCode.
     * @member {string} inviteCode
     * @memberof AcceptProviderInviteRequest
     * @instance
     */
    AcceptProviderInviteRequest.prototype.inviteCode = "";

    /**
     * AcceptProviderInviteRequest accepted.
     * @member {boolean} accepted
     * @memberof AcceptProviderInviteRequest
     * @instance
     */
    AcceptProviderInviteRequest.prototype.accepted = false;

    /**
     * Creates a new AcceptProviderInviteRequest instance using the specified properties.
     * @function create
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {IAcceptProviderInviteRequest=} [properties] Properties to set
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest instance
     */
    AcceptProviderInviteRequest.create = function create(properties) {
        return new AcceptProviderInviteRequest(properties);
    };

    /**
     * Encodes the specified AcceptProviderInviteRequest message. Does not implicitly {@link AcceptProviderInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {IAcceptProviderInviteRequest} message AcceptProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
        if (message.accepted != null && Object.hasOwnProperty.call(message, "accepted"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.accepted);
        return writer;
    };

    /**
     * Encodes the specified AcceptProviderInviteRequest message, length delimited. Does not implicitly {@link AcceptProviderInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {IAcceptProviderInviteRequest} message AcceptProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptProviderInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptProviderInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.inviteCode = reader.string();
                    break;
                }
            case 2: {
                    message.accepted = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptProviderInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptProviderInviteRequest message.
     * @function verify
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptProviderInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        if (message.accepted != null && message.hasOwnProperty("accepted"))
            if (typeof message.accepted !== "boolean")
                return "accepted: boolean expected";
        return null;
    };

    /**
     * Creates an AcceptProviderInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptProviderInviteRequest} AcceptProviderInviteRequest
     */
    AcceptProviderInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptProviderInviteRequest)
            return object;
        var message = new $root.AcceptProviderInviteRequest();
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        if (object.accepted != null)
            message.accepted = Boolean(object.accepted);
        return message;
    };

    /**
     * Creates a plain object from an AcceptProviderInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {AcceptProviderInviteRequest} message AcceptProviderInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptProviderInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.inviteCode = "";
            object.accepted = false;
        }
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        if (message.accepted != null && message.hasOwnProperty("accepted"))
            object.accepted = message.accepted;
        return object;
    };

    /**
     * Converts this AcceptProviderInviteRequest to JSON.
     * @function toJSON
     * @memberof AcceptProviderInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptProviderInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptProviderInviteRequest
     * @function getTypeUrl
     * @memberof AcceptProviderInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptProviderInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptProviderInviteRequest";
    };

    return AcceptProviderInviteRequest;
})();

$root.AcceptProviderInviteResponse = (function() {

    /**
     * Properties of an AcceptProviderInviteResponse.
     * @exports IAcceptProviderInviteResponse
     * @interface IAcceptProviderInviteResponse
     * @property {boolean|null} [okay] AcceptProviderInviteResponse okay
     * @property {IProviderProfileProto|null} [profile] AcceptProviderInviteResponse profile
     */

    /**
     * Constructs a new AcceptProviderInviteResponse.
     * @exports AcceptProviderInviteResponse
     * @classdesc Represents an AcceptProviderInviteResponse.
     * @implements IAcceptProviderInviteResponse
     * @constructor
     * @param {IAcceptProviderInviteResponse=} [properties] Properties to set
     */
    function AcceptProviderInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptProviderInviteResponse okay.
     * @member {boolean} okay
     * @memberof AcceptProviderInviteResponse
     * @instance
     */
    AcceptProviderInviteResponse.prototype.okay = false;

    /**
     * AcceptProviderInviteResponse profile.
     * @member {IProviderProfileProto|null|undefined} profile
     * @memberof AcceptProviderInviteResponse
     * @instance
     */
    AcceptProviderInviteResponse.prototype.profile = null;

    /**
     * Creates a new AcceptProviderInviteResponse instance using the specified properties.
     * @function create
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {IAcceptProviderInviteResponse=} [properties] Properties to set
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse instance
     */
    AcceptProviderInviteResponse.create = function create(properties) {
        return new AcceptProviderInviteResponse(properties);
    };

    /**
     * Encodes the specified AcceptProviderInviteResponse message. Does not implicitly {@link AcceptProviderInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {IAcceptProviderInviteResponse} message AcceptProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.ProviderProfileProto.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AcceptProviderInviteResponse message, length delimited. Does not implicitly {@link AcceptProviderInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {IAcceptProviderInviteResponse} message AcceptProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptProviderInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptProviderInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptProviderInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.profile = $root.ProviderProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptProviderInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptProviderInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptProviderInviteResponse message.
     * @function verify
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptProviderInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.ProviderProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates an AcceptProviderInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptProviderInviteResponse} AcceptProviderInviteResponse
     */
    AcceptProviderInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptProviderInviteResponse)
            return object;
        var message = new $root.AcceptProviderInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".AcceptProviderInviteResponse.profile: object expected");
            message.profile = $root.ProviderProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from an AcceptProviderInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {AcceptProviderInviteResponse} message AcceptProviderInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptProviderInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.profile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.ProviderProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this AcceptProviderInviteResponse to JSON.
     * @function toJSON
     * @memberof AcceptProviderInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptProviderInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptProviderInviteResponse
     * @function getTypeUrl
     * @memberof AcceptProviderInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptProviderInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptProviderInviteResponse";
    };

    return AcceptProviderInviteResponse;
})();

$root.CheckProviderInviteRequest = (function() {

    /**
     * Properties of a CheckProviderInviteRequest.
     * @exports ICheckProviderInviteRequest
     * @interface ICheckProviderInviteRequest
     * @property {string|null} [networkId] CheckProviderInviteRequest networkId
     * @property {string|null} [inviteCode] CheckProviderInviteRequest inviteCode
     */

    /**
     * Constructs a new CheckProviderInviteRequest.
     * @exports CheckProviderInviteRequest
     * @classdesc Represents a CheckProviderInviteRequest.
     * @implements ICheckProviderInviteRequest
     * @constructor
     * @param {ICheckProviderInviteRequest=} [properties] Properties to set
     */
    function CheckProviderInviteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckProviderInviteRequest networkId.
     * @member {string} networkId
     * @memberof CheckProviderInviteRequest
     * @instance
     */
    CheckProviderInviteRequest.prototype.networkId = "";

    /**
     * CheckProviderInviteRequest inviteCode.
     * @member {string} inviteCode
     * @memberof CheckProviderInviteRequest
     * @instance
     */
    CheckProviderInviteRequest.prototype.inviteCode = "";

    /**
     * Creates a new CheckProviderInviteRequest instance using the specified properties.
     * @function create
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {ICheckProviderInviteRequest=} [properties] Properties to set
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest instance
     */
    CheckProviderInviteRequest.create = function create(properties) {
        return new CheckProviderInviteRequest(properties);
    };

    /**
     * Encodes the specified CheckProviderInviteRequest message. Does not implicitly {@link CheckProviderInviteRequest.verify|verify} messages.
     * @function encode
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {ICheckProviderInviteRequest} message CheckProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.networkId != null && Object.hasOwnProperty.call(message, "networkId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.networkId);
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCode);
        return writer;
    };

    /**
     * Encodes the specified CheckProviderInviteRequest message, length delimited. Does not implicitly {@link CheckProviderInviteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {ICheckProviderInviteRequest} message CheckProviderInviteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckProviderInviteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckProviderInviteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.networkId = reader.string();
                    break;
                }
            case 2: {
                    message.inviteCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckProviderInviteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckProviderInviteRequest message.
     * @function verify
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckProviderInviteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            if (!$util.isString(message.networkId))
                return "networkId: string expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        return null;
    };

    /**
     * Creates a CheckProviderInviteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckProviderInviteRequest} CheckProviderInviteRequest
     */
    CheckProviderInviteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckProviderInviteRequest)
            return object;
        var message = new $root.CheckProviderInviteRequest();
        if (object.networkId != null)
            message.networkId = String(object.networkId);
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        return message;
    };

    /**
     * Creates a plain object from a CheckProviderInviteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {CheckProviderInviteRequest} message CheckProviderInviteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckProviderInviteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.networkId = "";
            object.inviteCode = "";
        }
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            object.networkId = message.networkId;
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        return object;
    };

    /**
     * Converts this CheckProviderInviteRequest to JSON.
     * @function toJSON
     * @memberof CheckProviderInviteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckProviderInviteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckProviderInviteRequest
     * @function getTypeUrl
     * @memberof CheckProviderInviteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckProviderInviteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckProviderInviteRequest";
    };

    return CheckProviderInviteRequest;
})();

$root.CheckProviderInviteResponse = (function() {

    /**
     * Properties of a CheckProviderInviteResponse.
     * @exports ICheckProviderInviteResponse
     * @interface ICheckProviderInviteResponse
     * @property {boolean|null} [okay] CheckProviderInviteResponse okay
     */

    /**
     * Constructs a new CheckProviderInviteResponse.
     * @exports CheckProviderInviteResponse
     * @classdesc Represents a CheckProviderInviteResponse.
     * @implements ICheckProviderInviteResponse
     * @constructor
     * @param {ICheckProviderInviteResponse=} [properties] Properties to set
     */
    function CheckProviderInviteResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckProviderInviteResponse okay.
     * @member {boolean} okay
     * @memberof CheckProviderInviteResponse
     * @instance
     */
    CheckProviderInviteResponse.prototype.okay = false;

    /**
     * Creates a new CheckProviderInviteResponse instance using the specified properties.
     * @function create
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {ICheckProviderInviteResponse=} [properties] Properties to set
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse instance
     */
    CheckProviderInviteResponse.create = function create(properties) {
        return new CheckProviderInviteResponse(properties);
    };

    /**
     * Encodes the specified CheckProviderInviteResponse message. Does not implicitly {@link CheckProviderInviteResponse.verify|verify} messages.
     * @function encode
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {ICheckProviderInviteResponse} message CheckProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        return writer;
    };

    /**
     * Encodes the specified CheckProviderInviteResponse message, length delimited. Does not implicitly {@link CheckProviderInviteResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {ICheckProviderInviteResponse} message CheckProviderInviteResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckProviderInviteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckProviderInviteResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckProviderInviteResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckProviderInviteResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckProviderInviteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckProviderInviteResponse message.
     * @function verify
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckProviderInviteResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        return null;
    };

    /**
     * Creates a CheckProviderInviteResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckProviderInviteResponse} CheckProviderInviteResponse
     */
    CheckProviderInviteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckProviderInviteResponse)
            return object;
        var message = new $root.CheckProviderInviteResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        return message;
    };

    /**
     * Creates a plain object from a CheckProviderInviteResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {CheckProviderInviteResponse} message CheckProviderInviteResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckProviderInviteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        return object;
    };

    /**
     * Converts this CheckProviderInviteResponse to JSON.
     * @function toJSON
     * @memberof CheckProviderInviteResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckProviderInviteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckProviderInviteResponse
     * @function getTypeUrl
     * @memberof CheckProviderInviteResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckProviderInviteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckProviderInviteResponse";
    };

    return CheckProviderInviteResponse;
})();

$root.UpdateAvailabilityRequest = (function() {

    /**
     * Properties of an UpdateAvailabilityRequest.
     * @exports IUpdateAvailabilityRequest
     * @interface IUpdateAvailabilityRequest
     * @property {number|null} [dayOfWeek] UpdateAvailabilityRequest dayOfWeek
     * @property {space.kenko.proto.ILocalTimeProto|null} [startTime] UpdateAvailabilityRequest startTime
     * @property {space.kenko.proto.ILocalTimeProto|null} [endTime] UpdateAvailabilityRequest endTime
     * @property {boolean|null} [removing] UpdateAvailabilityRequest removing
     */

    /**
     * Constructs a new UpdateAvailabilityRequest.
     * @exports UpdateAvailabilityRequest
     * @classdesc Represents an UpdateAvailabilityRequest.
     * @implements IUpdateAvailabilityRequest
     * @constructor
     * @param {IUpdateAvailabilityRequest=} [properties] Properties to set
     */
    function UpdateAvailabilityRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateAvailabilityRequest dayOfWeek.
     * @member {number} dayOfWeek
     * @memberof UpdateAvailabilityRequest
     * @instance
     */
    UpdateAvailabilityRequest.prototype.dayOfWeek = 0;

    /**
     * UpdateAvailabilityRequest startTime.
     * @member {space.kenko.proto.ILocalTimeProto|null|undefined} startTime
     * @memberof UpdateAvailabilityRequest
     * @instance
     */
    UpdateAvailabilityRequest.prototype.startTime = null;

    /**
     * UpdateAvailabilityRequest endTime.
     * @member {space.kenko.proto.ILocalTimeProto|null|undefined} endTime
     * @memberof UpdateAvailabilityRequest
     * @instance
     */
    UpdateAvailabilityRequest.prototype.endTime = null;

    /**
     * UpdateAvailabilityRequest removing.
     * @member {boolean} removing
     * @memberof UpdateAvailabilityRequest
     * @instance
     */
    UpdateAvailabilityRequest.prototype.removing = false;

    /**
     * Creates a new UpdateAvailabilityRequest instance using the specified properties.
     * @function create
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {IUpdateAvailabilityRequest=} [properties] Properties to set
     * @returns {UpdateAvailabilityRequest} UpdateAvailabilityRequest instance
     */
    UpdateAvailabilityRequest.create = function create(properties) {
        return new UpdateAvailabilityRequest(properties);
    };

    /**
     * Encodes the specified UpdateAvailabilityRequest message. Does not implicitly {@link UpdateAvailabilityRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {IUpdateAvailabilityRequest} message UpdateAvailabilityRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAvailabilityRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.dayOfWeek != null && Object.hasOwnProperty.call(message, "dayOfWeek"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dayOfWeek);
        if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
            $root.space.kenko.proto.LocalTimeProto.encode(message.startTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
            $root.space.kenko.proto.LocalTimeProto.encode(message.endTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.removing != null && Object.hasOwnProperty.call(message, "removing"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.removing);
        return writer;
    };

    /**
     * Encodes the specified UpdateAvailabilityRequest message, length delimited. Does not implicitly {@link UpdateAvailabilityRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {IUpdateAvailabilityRequest} message UpdateAvailabilityRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAvailabilityRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateAvailabilityRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateAvailabilityRequest} UpdateAvailabilityRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAvailabilityRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateAvailabilityRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.dayOfWeek = reader.int32();
                    break;
                }
            case 2: {
                    message.startTime = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.endTime = $root.space.kenko.proto.LocalTimeProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.removing = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateAvailabilityRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateAvailabilityRequest} UpdateAvailabilityRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAvailabilityRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateAvailabilityRequest message.
     * @function verify
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateAvailabilityRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
            if (!$util.isInteger(message.dayOfWeek))
                return "dayOfWeek: integer expected";
        if (message.startTime != null && message.hasOwnProperty("startTime")) {
            var error = $root.space.kenko.proto.LocalTimeProto.verify(message.startTime);
            if (error)
                return "startTime." + error;
        }
        if (message.endTime != null && message.hasOwnProperty("endTime")) {
            var error = $root.space.kenko.proto.LocalTimeProto.verify(message.endTime);
            if (error)
                return "endTime." + error;
        }
        if (message.removing != null && message.hasOwnProperty("removing"))
            if (typeof message.removing !== "boolean")
                return "removing: boolean expected";
        return null;
    };

    /**
     * Creates an UpdateAvailabilityRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateAvailabilityRequest} UpdateAvailabilityRequest
     */
    UpdateAvailabilityRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateAvailabilityRequest)
            return object;
        var message = new $root.UpdateAvailabilityRequest();
        if (object.dayOfWeek != null)
            message.dayOfWeek = object.dayOfWeek | 0;
        if (object.startTime != null) {
            if (typeof object.startTime !== "object")
                throw TypeError(".UpdateAvailabilityRequest.startTime: object expected");
            message.startTime = $root.space.kenko.proto.LocalTimeProto.fromObject(object.startTime);
        }
        if (object.endTime != null) {
            if (typeof object.endTime !== "object")
                throw TypeError(".UpdateAvailabilityRequest.endTime: object expected");
            message.endTime = $root.space.kenko.proto.LocalTimeProto.fromObject(object.endTime);
        }
        if (object.removing != null)
            message.removing = Boolean(object.removing);
        return message;
    };

    /**
     * Creates a plain object from an UpdateAvailabilityRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {UpdateAvailabilityRequest} message UpdateAvailabilityRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateAvailabilityRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.dayOfWeek = 0;
            object.startTime = null;
            object.endTime = null;
            object.removing = false;
        }
        if (message.dayOfWeek != null && message.hasOwnProperty("dayOfWeek"))
            object.dayOfWeek = message.dayOfWeek;
        if (message.startTime != null && message.hasOwnProperty("startTime"))
            object.startTime = $root.space.kenko.proto.LocalTimeProto.toObject(message.startTime, options);
        if (message.endTime != null && message.hasOwnProperty("endTime"))
            object.endTime = $root.space.kenko.proto.LocalTimeProto.toObject(message.endTime, options);
        if (message.removing != null && message.hasOwnProperty("removing"))
            object.removing = message.removing;
        return object;
    };

    /**
     * Converts this UpdateAvailabilityRequest to JSON.
     * @function toJSON
     * @memberof UpdateAvailabilityRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateAvailabilityRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateAvailabilityRequest
     * @function getTypeUrl
     * @memberof UpdateAvailabilityRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateAvailabilityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateAvailabilityRequest";
    };

    return UpdateAvailabilityRequest;
})();

$root.UpdateAvailabilityResponse = (function() {

    /**
     * Properties of an UpdateAvailabilityResponse.
     * @exports IUpdateAvailabilityResponse
     * @interface IUpdateAvailabilityResponse
     * @property {boolean|null} [okay] UpdateAvailabilityResponse okay
     * @property {string|null} [error] UpdateAvailabilityResponse error
     * @property {IProviderProfileProto|null} [newProfile] UpdateAvailabilityResponse newProfile
     */

    /**
     * Constructs a new UpdateAvailabilityResponse.
     * @exports UpdateAvailabilityResponse
     * @classdesc Represents an UpdateAvailabilityResponse.
     * @implements IUpdateAvailabilityResponse
     * @constructor
     * @param {IUpdateAvailabilityResponse=} [properties] Properties to set
     */
    function UpdateAvailabilityResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateAvailabilityResponse okay.
     * @member {boolean} okay
     * @memberof UpdateAvailabilityResponse
     * @instance
     */
    UpdateAvailabilityResponse.prototype.okay = false;

    /**
     * UpdateAvailabilityResponse error.
     * @member {string} error
     * @memberof UpdateAvailabilityResponse
     * @instance
     */
    UpdateAvailabilityResponse.prototype.error = "";

    /**
     * UpdateAvailabilityResponse newProfile.
     * @member {IProviderProfileProto|null|undefined} newProfile
     * @memberof UpdateAvailabilityResponse
     * @instance
     */
    UpdateAvailabilityResponse.prototype.newProfile = null;

    /**
     * Creates a new UpdateAvailabilityResponse instance using the specified properties.
     * @function create
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {IUpdateAvailabilityResponse=} [properties] Properties to set
     * @returns {UpdateAvailabilityResponse} UpdateAvailabilityResponse instance
     */
    UpdateAvailabilityResponse.create = function create(properties) {
        return new UpdateAvailabilityResponse(properties);
    };

    /**
     * Encodes the specified UpdateAvailabilityResponse message. Does not implicitly {@link UpdateAvailabilityResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {IUpdateAvailabilityResponse} message UpdateAvailabilityResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAvailabilityResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
        if (message.newProfile != null && Object.hasOwnProperty.call(message, "newProfile"))
            $root.ProviderProfileProto.encode(message.newProfile, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateAvailabilityResponse message, length delimited. Does not implicitly {@link UpdateAvailabilityResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {IUpdateAvailabilityResponse} message UpdateAvailabilityResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAvailabilityResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateAvailabilityResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateAvailabilityResponse} UpdateAvailabilityResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAvailabilityResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateAvailabilityResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.error = reader.string();
                    break;
                }
            case 3: {
                    message.newProfile = $root.ProviderProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateAvailabilityResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateAvailabilityResponse} UpdateAvailabilityResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAvailabilityResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateAvailabilityResponse message.
     * @function verify
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateAvailabilityResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        if (message.newProfile != null && message.hasOwnProperty("newProfile")) {
            var error = $root.ProviderProfileProto.verify(message.newProfile);
            if (error)
                return "newProfile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateAvailabilityResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateAvailabilityResponse} UpdateAvailabilityResponse
     */
    UpdateAvailabilityResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateAvailabilityResponse)
            return object;
        var message = new $root.UpdateAvailabilityResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.error != null)
            message.error = String(object.error);
        if (object.newProfile != null) {
            if (typeof object.newProfile !== "object")
                throw TypeError(".UpdateAvailabilityResponse.newProfile: object expected");
            message.newProfile = $root.ProviderProfileProto.fromObject(object.newProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateAvailabilityResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {UpdateAvailabilityResponse} message UpdateAvailabilityResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateAvailabilityResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.error = "";
            object.newProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        if (message.newProfile != null && message.hasOwnProperty("newProfile"))
            object.newProfile = $root.ProviderProfileProto.toObject(message.newProfile, options);
        return object;
    };

    /**
     * Converts this UpdateAvailabilityResponse to JSON.
     * @function toJSON
     * @memberof UpdateAvailabilityResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateAvailabilityResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateAvailabilityResponse
     * @function getTypeUrl
     * @memberof UpdateAvailabilityResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateAvailabilityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateAvailabilityResponse";
    };

    return UpdateAvailabilityResponse;
})();

$root.space = (function() {

    /**
     * Namespace space.
     * @exports space
     * @namespace
     */
    var space = {};

    space.kenko = (function() {

        /**
         * Namespace kenko.
         * @memberof space
         * @namespace
         */
        var kenko = {};

        kenko.proto = (function() {

            /**
             * Namespace proto.
             * @memberof space.kenko
             * @namespace
             */
            var proto = {};

            proto.LocalTimeProto = (function() {

                /**
                 * Properties of a LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @interface ILocalTimeProto
                 * @property {number|null} [hour] LocalTimeProto hour
                 * @property {number|null} [minute] LocalTimeProto minute
                 */

                /**
                 * Constructs a new LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LocalTimeProto.
                 * @implements ILocalTimeProto
                 * @constructor
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 */
                function LocalTimeProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalTimeProto hour.
                 * @member {number} hour
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.hour = 0;

                /**
                 * LocalTimeProto minute.
                 * @member {number} minute
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.minute = 0;

                /**
                 * Creates a new LocalTimeProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto instance
                 */
                LocalTimeProto.create = function create(properties) {
                    return new LocalTimeProto(properties);
                };

                /**
                 * Encodes the specified LocalTimeProto message. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.hour);
                    if (message.minute != null && Object.hasOwnProperty.call(message, "minute"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minute);
                    return writer;
                };

                /**
                 * Encodes the specified LocalTimeProto message, length delimited. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LocalTimeProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hour = reader.int32();
                                break;
                            }
                        case 2: {
                                message.minute = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocalTimeProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalTimeProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        if (!$util.isInteger(message.hour))
                            return "hour: integer expected";
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        if (!$util.isInteger(message.minute))
                            return "minute: integer expected";
                    return null;
                };

                /**
                 * Creates a LocalTimeProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 */
                LocalTimeProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LocalTimeProto)
                        return object;
                    var message = new $root.space.kenko.proto.LocalTimeProto();
                    if (object.hour != null)
                        message.hour = object.hour | 0;
                    if (object.minute != null)
                        message.minute = object.minute | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a LocalTimeProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.LocalTimeProto} message LocalTimeProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalTimeProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.hour = 0;
                        object.minute = 0;
                    }
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        object.hour = message.hour;
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        object.minute = message.minute;
                    return object;
                };

                /**
                 * Converts this LocalTimeProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalTimeProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalTimeProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalTimeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LocalTimeProto";
                };

                return LocalTimeProto;
            })();

            return proto;
        })();

        return kenko;
    })();

    return space;
})();

module.exports = $root;
