import React, {createContext, ReactElement, useContext, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "./rpcSender";
import {GetProviderProfileRequest, GetProviderProfileResponse, IProviderProfileProto} from "./provider_api";

export const ProviderProfileContext = createContext<{
  profile: IProviderProfileProto | null | undefined,
  // For manually setting the profile. You can use this after a sub-route updates
  // the profile and successfully returns via rpc.
  setProfile: (profile: IProviderProfileProto | null | undefined) => void;
  profileLoading: boolean,
  profileError: string | null,
}>({
  profile: null,
  setProfile: () => {},
  profileLoading: true,
  profileError: null,
});

export const useProviderProfile = () => {
  return useContext(ProviderProfileContext)
}

interface Props {
  children?: ReactElement
}

/**
 * This should return the proto configuration for this unicorn.
 * It contains all the unicorn's special characteristics, like
 * the logo, description, which modules are on the website, etc.
 *
 *
 * @return {JSX.Element}
 * @constructor
 */
export const ProviderProfileProvider = (props: Props) => {

  const {user, getAccessTokenSilently} = useAuth0();

  const [providerProfile, setProviderProfile] = useState<IProviderProfileProto | null | undefined>(null);
  const [profileLoading, setProfileLoading] = useState<boolean>(false);
  const [profileError, setProfileError] = useState<string | null>(null);

  useEffect(() => {

    if (!user) {
      setProviderProfile(null);
      setProfileLoading(false);
      setProfileError(null);
      return;
    }

    // Download it immediately at the beginning...
    console.log('[Fetching provider profile...]');
    setProviderProfile(null);
    setProfileLoading(true);
    setProfileError(null);

    SendRpc(getAccessTokenSilently, "get_provider_profile",
        GetProviderProfileRequest.encode(new GetProviderProfileRequest()).finish())
        .then(response => {
          let profileResponse = GetProviderProfileResponse.decode(response);
          console.log("Provider profile: " + JSON.stringify(profileResponse.toJSON()))
          setProviderProfile(profileResponse.profile || null);
        })
        .catch(error => {
          setProfileError(error)
        })
        .finally(() => {
          setProfileLoading(false);
        });

  }, [user]);

  return (<ProviderProfileContext.Provider value={{
    profile: providerProfile,
    setProfile: setProviderProfile,
    profileLoading: profileLoading,
    profileError: profileError
  }}>
    {props.children}
  </ProviderProfileContext.Provider>);
};
