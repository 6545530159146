import React, {useEffect, useRef, useState} from 'react';
import {
  AdvancedMarker,
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
  useAdvancedMarkerRef,
  useMap,
  useMapsLibrary
} from '@vis.gl/react-google-maps';

const API_KEY = ("AIzaSyCFJhMw3rXpVRXvhxjFTmELaqdHigbQ2nw");

interface Props {
  // formatted_address is like "2143 Cumberland Ave, Charlotte, NC 28203, USA"
  // geometry.location is the other useful one like {"lat":35.1955508,"lng":-80.844284}
  onLocationPicked: (location: google.maps.places.PlaceResult | null) => void,
}

const SurveyMapInput = (props: Props) => {

  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
      <APIProvider
          apiKey={API_KEY}
          solutionChannel='GMP_devsite_samples_v3_rgmautocomplete'>

        <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
          <PlaceAutocomplete onPlaceSelect={place => {
            setSelectedPlace(place);
            props.onLocationPicked(place);
          }}/>

          {/* the map onyl shows up once you select something */}
          {selectedPlace && <Map
              style={{width: '400px', height: '300px'}}
              mapId={'bf51a910020fa25a'}
              zoom={15}
              center={selectedPlace.geometry?.location || null}
              gestureHandling={'none'}
              disableDefaultUI={true}
          >
            <AdvancedMarker ref={markerRef} position={null}/>
          </Map>}
          <MapHandler place={selectedPlace} marker={marker}/>
        </div>

      </APIProvider>
  );
};

interface MapHandlerProps {
  place: google.maps.places.PlaceResult | null;
  marker: google.maps.marker.AdvancedMarkerElement | null;
}

const MapHandler = ({place, marker}: MapHandlerProps) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }
    marker.position = place.geometry?.location;
  }, [map, place, marker]);

  return null;
};

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

const PlaceAutocomplete = ({onPlaceSelect}: PlaceAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
      useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address']
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      console.log(JSON.stringify(placeAutocomplete.getPlace()));
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
      <div>
        <input style={{width: '400px', height: 30, paddingLeft: 8}} ref={inputRef}/>
      </div>
  );
};

export default SurveyMapInput;
