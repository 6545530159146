import React, {useContext} from 'react';
import './Provider.css';
import NewProviderSurvey from "./Survey/NewProviderSurvey";
import {ProviderProfileContext} from "../../ProviderProfileProvider";
import {Routes} from "react-router";
import {Route} from "react-router-dom";
import {DeleteBusinessDebug} from "./DeleteBusinessDebug";
import {Services} from "./Services";
import {ProviderInfo} from "./ProviderInfo";
import {LeftNavIcon} from "./LeftNavIcon";
import {MySchedule} from "./Schedule/MySchedule";

/**
 * The user is guaranteed to be logged in at this point because the
 * authentication happens in App.tsx.
 */
export const ProviderHome = () => {

  const {profile} = useContext(ProviderProfileContext);

  // This should never happen here cause the parent state forbids it.
  if (!profile) {
    return <div>no profile</div>;
  }

  if (!profile.providerShortUrl) {
    // Survey has to be taken.
    // HACK we should have a better indicated for this.

    console.log('survey///', profile)
    
    return (
        <div style={{flex: 1, display: 'flex', flexDirection: 'row', width: '100%'}}>
          <NewProviderSurvey/>
        </div>
    );
  }

  return (
      <div style={{flex: 1, display: 'flex', flexDirection: 'row', width: '100%'}}>

        {/* The left nav bar containing all the tools */}
        <div style={{display: 'flex', flexDirection: 'column', flex: 0, backgroundColor: '#eee'}}>
          <LeftNavIcon text='My Info' link={'/'}/>
          <LeftNavIcon text='My Schedule' link={'/schedule'}/>
          <LeftNavIcon text='My Services' link={'/services'}/>
          <LeftNavIcon text='Delete Business' link={'/delete-business'}/>
          <br/>
        </div>

        {/* The main body of the tool- And the right which takes up the most of the page*/}
        <div style={{
          display: 'flex', flexDirection: 'column',
          flex: 1, textAlign: 'start', padding: 10,
        }}>

          <Routes>
            <Route path={'/'} element={<ProviderInfo/>}/>,
            <Route path={'/schedule'} element={<MySchedule/>}/>,
            <Route path={'/services'} element={<Services/>}/>,
            <Route path={'/delete-business'} element={<DeleteBusinessDebug/>}/>,
          </Routes>

        </div>
      </div>
  );
}
